import { useEffect, useRef, useState } from "react";
import config from "../config";
import './LeadAppointments.css';
const LeadAppointments = (props) => {
  const [appointments, setAppointments] = useState([]);
  const deleteAppointment = (appointmentId) => {
    fetch(config.apiURL + "/appointments/" + appointmentId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-karya-token": window.localStorage.getItem("token"),
      },
    })
      .then(() => {
        const clone = [...appointments];
        clone.splice(appointments.indexOf(appointments.find((a) => a.Id === appointmentId)), 1);
        setAppointments(clone);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetch(config.apiURL + "/appointments/" + props.leadId, {
      headers: {
        "Content-Type": "application/json",
        "x-karya-token": window.localStorage.getItem("token"),
      },
    })
      .then((response) => {
        response.json().then((data) => {
          setAppointments(data);
        });
      })
      .catch(() => {});
  }, [props.leadId]);

  const dateRef = useRef(null);
  const timeRef = useRef(null);
  const noteRef = useRef(null);

  const createAppointment = (e) => {
    e.preventDefault();

    const form = e.target.value;
    console.log(form);

    fetch(config.apiURL + "/appointments/" + props.leadId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-karya-token": window.localStorage.getItem("token"),
      },
      body: JSON.stringify({
        lead_id: props.leadId,
        date: dateRef.current.value,
        time: timeRef.current.value,
        notes: noteRef.current.value,
      }),
    })
      .then((response) => {
        response.json().then((data) => {
          setAppointments([...appointments, data]);
          dateRef.current.value = "";
          timeRef.current.value = "";
          noteRef.current.value = "";
        });
      })
      .catch(() => {});
  };
  return (
    <div>
      <form className="appointment-form" onSubmit={createAppointment}>
        <div className="gray-form-container appointment-form-container">
          <div>
            <label>Appointment Date</label>
            <input className="form-control" required type="date" ref={dateRef} />
          </div>
          <div>
            <label>Appointment Time</label>
            <input className="form-control" required type="time" ref={timeRef} />
          </div>
          <div>
            <label>Message</label>
            <input style={{width:'500px'}} className="form-control" type="text" ref={noteRef} />
          </div>
          <div>
            <label>&nbsp;</label>
            <button className="btn btn-outline-success" type="submit">
              <i className="fa-solid fa-plus"></i> Create Appointment
            </button>
          </div>
        </div>
      </form>

      <table className="table appointment-table table-striped table-aligned-left mt-4">
        <thead>
          <tr>
            <th>Appointment Date</th>
            <th>Message</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {appointments.length === 0 && (
            <tr>
              <td colSpan="3" className="text-center">
                No appointments found
              </td>
            </tr>
          )}
          {appointments.map((a) => (
            <tr>
              <td style={{width:'200px'}}>{a.AppointmentDate} {a.AppointmentTime}</td>
              <td>{a.Message}</td>
              <td className="text-right">
              
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => deleteAppointment(a.Id)}
                >
                            <i className="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeadAppointments;
