import { useRef } from "react";
import config from "../config";
import { Contact, snackError } from "../Lead/Lead";
import { localDate, utcNOW } from "../Util";

const NotesAndFollowUps = (props: any) => {
    const userState = props.userState;
    const setUserState = props.setUserState;
    const newNoteRef = useRef<HTMLTextAreaElement | null>(null);
    const newFollowUpRef = useRef<HTMLInputElement | null>(null);


    const handleFollowUpChange = (index: number) => {
        let notes = [...userState.Notes];
        const date = notes[index].followUpDone ? null : new Date();
        fetch(
            config.apiURL + "/item/" + userState.Id + "/follow-up/" + notes[index].id,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "x-karya-token": localStorage.getItem("token")!,
                },
                body: JSON.stringify({
                    date: date,
                }),
            }
        ).then((res) => {
            if (res.ok) {
                notes[index].followUpDone = date;
                setUserState((curr: any) => ({
                    ...curr,
                    Notes: notes,
                }));
            } else {
                snackError("An error occured while setting follow up status.");
            }
        });
    };

    const handleNoteDelete = (index: number) => {
        const noteId = userState.Notes[index].id;
        fetch(config.apiURL + "/item/" + userState.Id + "/note/" + noteId, {
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                "x-karya-token": localStorage.getItem("token")!,
            },
        }).then((res) => {
            if (res.ok) {
                let notes = [...userState.Notes];
                notes.splice(index, 1);
                setUserState((curr: any) => ({
                    ...curr,
                    Notes: notes,
                }));
            }
        });
    };

    const handleAddNote = () => {
        if (newNoteRef?.current?.value) {
            let value = newNoteRef.current.value;
            const note_to_create: Contact = {
                id: null,
                createdOn: utcNOW(),
                emailDetails: null,
                followUpDone: null,
                from: null,
                isOutbound: false,
                sid: null,
                to: null,
                recordingId: null,
                type: "Note",
                content: value,
                followUpDate: newFollowUpRef?.current?.value || null,
                createdBy: localStorage.name!,
            };
            fetch(config.apiURL + "/item/" + userState.Id + "/note", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "x-karya-token": localStorage.getItem("token")!,
                },
                body: JSON.stringify(note_to_create),
            }).then(async (res) => {
                if (res.ok) {
                    const new_note = await res.json();
                    let clone = { ...userState };
                    clone.Notes.unshift(new_note);
                    setUserState(clone);
                    if (newFollowUpRef?.current?.value) {
                        newFollowUpRef.current.value = "";
                    }
                    if (newNoteRef?.current?.value) newNoteRef!.current.value = "";
                } else {
                    snackError("An error occured while saving the note.");
                }
            });
        }
    };

    return (
        <div className="row">
            <div className="col">
                <div className="form-group">
                    <div className="gray-form-container">
                        <textarea
                            rows={3}
                            placeholder="Add new note...."
                            className="form-control"
                            ref={newNoteRef}
                        />
                        <div className="row  mb-2  mt-2">
                            <div className="col-6">
                                <label>Follow up date</label>
                                <input
                                    type="date"
                                    className="form-control inline-block"
                                    ref={newFollowUpRef}
                                />
                            </div>
                            <div className="col-6">
                                <div className="text-right mt-3">
                                    <button
                                        className="btn btn-outline-success"
                                        onClick={handleAddNote}
                                    >
                                        <i className="fa-solid fa-add"></i> Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {userState.Notes && userState.Notes.length > 0 && (
                    <table className="table appointment-table table-striped table-aligned-left mt-4">
                        <thead>
                            <tr>
                                <th style={{ width: "200px" }}>Created</th>
                                <th style={{ width: "200px" }}>Follow Up Date</th>
                                <th style={{ width: "200px" }}>Followed Up On</th>
                                <th>Message</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {userState.Notes.map((it: Contact, noteIndex: number) => (
                                <tr className="note-record">
                                    <td>
                                        <div style={{ fontSize: "12px" }}>
                                            {localDate(it.createdOn)}
                                        </div>
                                        <div>{it.createdBy}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {it.followUpDate &&
                                                localDate(it.followUpDate, false)}
                                        </div>
                                    </td>
                                    <td>
                                        {it.followUpDone && localDate(it.followUpDone, false)}
                                    </td>
                                    <td>
                                        <div>{it.content}</div>
                                    </td>
                                    <td align="right" style={{width:'220px', textAlign: 'right'}}>
                                        {it.followUpDate && (
                                            <button
                                                onClick={() => handleFollowUpChange(noteIndex)}
                                                className={`btn mr-2 btn-xs ${it.followUpDone
                                                    ? "btn-outline-warning"
                                                    : "btn-outline-primary"
                                                    }`}
                                            >
                                                <i className="fa-solid fa-calendar mr-2"></i>
                                                {!it.followUpDone && (
                                                    <span>Mark as Followed Up</span>
                                                )}

                                                {it.followUpDone && (
                                                    <span>Mark as Not Followed Up</span>
                                                )}
                                            </button>
                                        )}

                                        <button
                                            onClick={() => handleNoteDelete(noteIndex)}
                                            className="btn btn-sm btn-danger"
                                        >
                                            <i className="fa-solid fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
}

export default NotesAndFollowUps;