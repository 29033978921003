import { localDate } from "../Util";
import config from "../config";

const Notifications = (props: any) => {
  const markNotificationAsRead = (index: number) => {
    fetch(config.apiURL + "/notifications/" + props.notifications[index].Id, {
      method: "POST",
      headers: {
        "x-karya-token": localStorage.getItem("token")!,
        "content-type": "application/json",
      },
    }).then((res) => {
      if (res.ok) {
        const notifications = [...props.notifications];
        notifications[index].IsRead = true;
        props.setNotifications(notifications);
      }
    });
  };

  return (
    <table className="table table-v-middle table-striped">
      <thead>
        <tr>
          <th>Date</th>
          <th>Property</th>
          <th>Unit</th>
          <th>Name</th>
          <th>Is Current Resident</th>
          <th>End Balance</th>
          <th>Message</th>
          <th>Is Read</th>
        </tr>
      </thead>
      <tbody>
        {props.notifications.map((row: INotification, ndx: number) => (
          <tr className={row.IsRead === false ? "new-voicemail" : ""}>
            <td>{localDate(row.Date)}</td>
            <td>{row.PropertyName}</td>
            <td>{row.Unit}</td>
            <td>
              {row.ItemId && (
                <div>
                  <button
                    className="btn btn-link btn-name"
                    onClick={() => props.showUser(row.ItemId)}
                  >
                    {row.Name}
                  </button>
                </div>
              )}
            </td>
            <td>{row.IsCurrentResident ? "Yes" : "No"}</td>
            <td>${row.EndBalance}</td>
            <td>{row.Message}</td>
            <td>
              <button
                onClick={() => markNotificationAsRead(ndx)}
                className="btn btn-xs btn-danger"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        ))}

        {props.notifications.length === 0 && <tr>
          <td colSpan={8} className="text-center">No notifications found</td>
        </tr>}
      </tbody>
    </table>
  );
};
export default Notifications;

interface INotification {
  PropertyName: string;
  ItemId: string;
  Name: string;
  Id: number;
  Unit: string;
  IsCurrentResident: string;
  EndBalance: string;
  Message: string;
  Date: string;
  IsRead: boolean;
}