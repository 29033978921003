import { useRef } from "react";
import './Chat.css';
function Chat(props) {
  let messages = props.messages;
  const textRef = useRef(null);
 
  const handleSend = () => {
    const message = textRef.current.value.trim();
    if(message) {
        props.send(message.trim());
    }
    textRef.current.value = '';
  }

  return (
    <div id="chat">
      <div id="messages">
        {messages.map((message, ndx) => (
          <div key={ndx} className={message.isMe === true ? 'my-message' : 'received-message'}>
            <div>
              <p>{message.message}</p>
            </div>
          </div>
        ))}
      </div>
      <div id="input-box">
        <input ref={textRef} type="text" id="message-input" placeholder="Type your message" />
        <button onClick={handleSend} id="send-button" onclick="sendMessage()">
          Send
        </button>
      </div>
    </div>
  );
}

export default Chat;