import config from "./config";

const openDatabase = () => {
  return new Promise((succ, err) => {
    
    const request = indexedDB.open("recordingDB", 1);

    request.onerror = (event) => {
      console.log(event);
      err("Won't be able to record. Database error:", event.target.errorCode);
    };

    request.onsuccess = (event) => {
      succ(event.target.result);
    };

    request.onupgradeneeded = (event) => {
      let db = event.target.result;
      // db.createObjectStore("audioChunks", {
      //   keyPath: "id",
      //   autoIncrement: true,
      // });
      db.createObjectStore("videoChunks", {
        keyPath: "id",
        autoIncrement: true,
      });
      succ(db);
    };
  });
};

const initializeRecord = (roomId) => {
  var videos = document.getElementsByTagName("video");
  var video1 = videos[0];
  var video2 = videos[1];

  var video1TargetWidth = 100;
  var video1Aspect = video1.videoWidth / video1.videoHeight;

  var widthToHeight = video2.videoWidth / video2.videoHeight;
  var targetWidth = 500;
  var targetHeight = targetWidth / widthToHeight;

  if(isNaN(targetWidth) || isNaN(targetHeight)){
    setTimeout(() => {
      console.log('nan values');
      if(window.connection.activeRoomId) {
        initializeRecord(roomId);
        return;
      }
    }, 1000);
  }

  video1.classList.add("hidden");
  video2.classList.add("hidden");

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = targetWidth;
  canvas.height = targetHeight;
  console.log(targetWidth, targetHeight);

  // Draw the videos onto the canvas
  const drawVideos = () => {
    // Clear the canvas

    ctx.clearRect(0, 0, targetWidth, targetHeight);
    ctx.drawImage(video2, 0, 0, targetWidth, targetHeight);
    ctx.drawImage(
      video1,
      0,
      0,
      video1TargetWidth,
      video1TargetWidth / video1Aspect
    );
    requestAnimationFrame(drawVideos);
  };

  // Start drawing the videos onto the canvas
  drawVideos();
  document.getElementById("canvasArea").appendChild(canvas);
  window.scrollY = 0;

  //merge videos
  const audioContext = new AudioContext();
  const as1 = audioContext.createMediaStreamSource(video1.srcObject);
  const as2 = audioContext.createMediaStreamSource(video2.srcObject);
  const audioMerger = audioContext.createChannelMerger(2);
  as1.connect(audioMerger, 0, 0);
  as2.connect(audioMerger, 0, 1);
  const audioDestinationNode = audioContext.createMediaStreamDestination();
  audioMerger.connect(audioDestinationNode);

  // const audioRecorder = new MediaRecorder(mediaStreamDestination.stream);

  const canvasStream = canvas.captureStream();
  const combinedStream = new MediaStream();
  combinedStream.addTrack(audioDestinationNode.stream.getAudioTracks()[0]);
  combinedStream.addTrack(canvasStream.getVideoTracks()[0]);

  const combinedRecorder = new MediaRecorder(combinedStream);
  window.combinedRecorder = combinedRecorder;

  const saveChunkToDB = (storeName, chunk) => {
    openDatabase().then((db) => {
      const transaction = db.transaction([storeName], "readwrite");
      const store = transaction.objectStore(storeName);
      store.add({ blob: chunk, roomId: roomId });
    });
  };

  combinedRecorder.ondataavailable = (event) => {
    if (event.data && event.data.size > 0) {
      saveChunkToDB("videoChunks", event.data, roomId);
    }
  };

  combinedRecorder.start(1000);

  document.getElementById("video-grids").classList.add("hidden");
  //
};

const stopRecording = () => {
  console.log("Stopping recording...");
  try {
    window.combinedRecorder.stop();
  } catch (e) {}

 
  try {
    document.getElementsByTagName("canvas")[0].remove();
  } catch (e) {}
};

const fetchBlobsFromDB = (storeName) => {
  return new Promise((resolve, reject) => {
    openDatabase().then((db) => {
      const transaction = db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.openCursor();

      const blobsByRoom = {};

      request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const { blob, roomId } = cursor.value;
          if (!blobsByRoom[roomId]) {
            blobsByRoom[roomId] = [];
          }
          blobsByRoom[roomId].push({ blob, id: cursor.primaryKey });
          cursor.continue();
        } else {
          resolve(blobsByRoom);
        }
      };

      request.onerror = (event) => {
        reject(event.target.errorCode);
      };
    });
  });
};

const deleteBlobs = async (videoBlobsByRoom) => {
  return new Promise((resolve, reject) => {
    openDatabase().then((db) => {
      const transaction = db.transaction(
        ["videoChunks"],
        "readwrite"
      );
      // const audioStore = transaction.objectStore("audioChunks");
      const videoStore = transaction.objectStore("videoChunks");

      transaction.oncomplete = function () {
        console.log("Transaction completed: database modification finished.");
        resolve();
      };

      transaction.onerror = function (event) {
        console.log("Transaction not opened due to error: ", transaction.error);
        reject(transaction.error);
      };

      // for (let i = 0; i < audioBlobsByRoom.length; i++) {
      //   const request = audioStore.delete(audioBlobsByRoom[i].id);
      //   request.onerror = function (event) {
      //     console.error(
      //       "Error deleting audio chunk with id:",
      //       audioBlobsByRoom[i].id,
      //       event.target.error
      //     );
      //   };
      // }

      for (let i = 0; i < videoBlobsByRoom.length; i++) {
        const request = videoStore.delete(videoBlobsByRoom[i].id);
        request.onerror = function (event) {
          console.error(
            "Error deleting video chunk with id:",
            videoBlobsByRoom[i].id,
            event.target.error
          );
        };
      }
    });
  });
};

const uploadRecordingBlobs = async () => {
  // const audioData = await fetchBlobsFromDB("audioChunks");
  const videoData = await fetchBlobsFromDB("videoChunks");
  const roomIds = [
      // ...new Set([...Object.keys(audioData), 
      ...Object.keys(videoData)
    //),
  ];

  for (let i = 0; i < roomIds.length; i++) {
    const roomToUpload = roomIds[i];

    // const audioBlobsByRoom = (audioData[roomToUpload] || []).sort(
    //   (a, b) => parseInt(a.id.toString()) - parseInt(b.id.toString())
    // );
    const videoBlobsByRoom = (videoData[roomToUpload] || []).sort(
      (a, b) => parseInt(a.id.toString()) - parseInt(b.id.toString())
    );

    // const audioBlob = new Blob(
    //   audioBlobsByRoom.map((i) => i.blob),
    //   { type: "audio/webm" }
    // );
    
    const videoBlob = new Blob(
      videoBlobsByRoom.map((i) => i.blob),
      { type: "video/webm" }
    );

    const formData = new FormData();
    formData.append("video.webm", videoBlob);
    // formData.append("audio.webm", audioBlob);

    await fetch(config.apiURL + "/save_recording?roomId=" + roomToUpload, {
      method: "POST",
      headers: {
        "x-karya-token": window.localStorage.getItem("token"),
      },
      body: formData,
    });

    await deleteBlobs(videoBlobsByRoom);
  }
};

const startUploader = async () => {
  while (true) {
    try {
      await uploadRecordingBlobs();
    } catch (e) {
      console.log(e);
    }
    // Wait for 10 seconds before the next execution
    await new Promise((resolve) => setTimeout(resolve, 10000));
  }
};

if (window) {
  openDatabase()
    .then((_) => {
      startUploader()
        .then(() => {})
        .catch((err) => {
          alert(JSON.stringify(err || "Error in uploader"));
        });
    })
    .catch((err) => {
      alert(err);
    });
}

export { initializeRecord, stopRecording };
