import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";

const Tags = (props: any) => {
  const [selected, setSelected] = useState([] as string[]);
  const [isLoaded, setIsLoaded] = useState(false);

  let handleTagChange = (value: string[]) => {
    if (isLoaded) {
      setSelected(value);
      if (props.onChange) props.onChange(selected);
    }
  };

  useEffect(() => {
    if (props.data) {
      setSelected(props.data);
    }
    setTimeout(() => {
      setIsLoaded(true);
    }, 100);
  }, []);

  return (
    <div className="">
      <TagsInput
        value={selected}
        onChange={handleTagChange}
        placeHolder={props.placeholder}
      />
    </div>
  );
};

export default Tags;
