import moment from "moment";

export const localDate = (date, includeHOUR) => {
  if (!date) {
    return <></>;
  } else {
    let dObject = date;
    if (typeof date === "object") {
      dObject = date.toJSON();
    }

    if (includeHOUR) {
      return (
        <span> {moment.utc(dObject).local().format("MM/DD/YYYY hh:mm A")}</span>
      );
    } else {
      return <span> {moment.utc(dObject).local().format("MM/DD/YYYY")}</span>;
    }
  }
};

export const getMinutesPassed = (startTime) => {
  var difference = new Date(utcNOW()).getTime() - startTime.getTime();
  // This will give difference in milliseconds
  var resultInMinutes = Math.round(difference / 60000);
  return resultInMinutes;
};

export const cleanDBPhone = (str) => {
  if (str) {
    let val = str
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll("+1", "")
      .replaceAll(" ", "");

    if (val.length > 10) {
      val = val.substring(val.length - 10);
    }
    return val;
  }
  return str;
};

export const utcNOW = () => {
  let now = new Date();
  return new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds()
    )
  ).toJSON();
};

