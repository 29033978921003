import "./App.css";
import LoadingContext, { LoadingContextType } from "../LoadingContext";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./Main";
import Lead from "../Lead/Lead";

let contextInitialValue = new LoadingContextType();

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
    },
    {
      path: "/lead/:leadId",
      element: <Lead />,
    },
  ]);
  return (
    <LoadingContext.Provider value={contextInitialValue}>
      <div className="root-container">
        <RouterProvider router={router} />
      </div>
      <div className="loader">
        <img
          src="https://img.pikbest.com/png-images/20190918/cartoon-snail-loading-loading-gif-animation_2734139.png!bw700"
          alt="Loading..."
        ></img>
      </div>
    </LoadingContext.Provider>
  );
}

export default App;
