import { useEffect, useState } from "react";
import config from "../config";
import './SidePhone.css';
function SidePhone() {
    const logOut = () => {
        localStorage.clear();
        window.location.href = '/';
    }

    const setOnCall = (on_call) => {
        let status = on_call ? "not-available" : "available";
        document.getElementById("initTwilioContainer").classList.remove("unavailable");
        document.getElementById("initTwilioContainer").classList.remove("available");
        document.getElementById('ddlStatus').value = status;

        fetch(
            config.apiURL + "/availability/" + status,
            {
                headers: {
                    "content-type": "application/json",
                    "x-karya-token": localStorage.getItem("token"),
                },
                method: "POST",
            }
        ).then((res) => {
            if (res.ok) {
                if (status !== "available") {
                    document.getElementById("initTwilioContainer").classList.add("unavailable");
                }

                let durationInSeconds = 2;
                window["vt"].custom(
                    "Your status has been changed to " + status,
                    {
                        duration: durationInSeconds * 1000,
                        fadeDuration: durationInSeconds * 1000,
                        position: "top-right",
                    },
                    "success"
                );
            }
        });
    }

    const InitTwilio = () => {
        const TWILIO_TOKEN_URL = config.apiURL + "/token";
        const speakerDevices = document.getElementById("speaker-devices");
        const ringtoneDevices = document.getElementById("ringtone-devices");
        const outputVolumeBar = document.getElementById("output-volume");
        const inputVolumeBar = document.getElementById("input-volume");
        const volumeIndicators = document.getElementById("volume-indicators");
        const callButton = document.getElementById("button-call");
        const audioSelectionDiv = document.getElementById("output-selection");
        const getAudioDevicesButton = document.getElementById("get-devices");
        const logDiv = document.getElementById("log");
        const incomingCallDiv = document.getElementById("incoming-call");
        const outgoingCallDiv = document.getElementById("outgoing-call");
        const eventLogDiv = document.getElementById("eventLogDiv");
        const outgoingCallHangupButton = document.getElementById(
            "button-hangup-outgoing"
        );
        
        const incomingCallHangupButton = document.getElementById(
            "button-hangup-incoming"
        );
        const incomingCallAcceptButton = document.getElementById(
            "button-accept-incoming"
        );
        const incomingCallRejectButton = document.getElementById(
            "button-reject-incoming"
        );
        const phoneNumberInput = document.getElementById("phone-number");
        const incomingPhoneNumberEl = document.getElementById("incoming-number");
        const startupButton = document.getElementById("startup-button");

        callButton.classList.add("hide");

        let device;
        let accessToken;

        // AUDIO CONTROLS

        // Update the available ringtone and speaker devices
        function updateDevices(selectEl, selectedDevices) {
            selectEl.innerHTML = "";

            device.audio.availableOutputDevices.forEach(function (device, id) {
                let isActive = selectedDevices.size === 0 && id === "default";
                selectedDevices.forEach(function (device) {
                    if (device.deviceId === id) {
                        isActive = true;
                    }
                });

                const option = document.createElement("option");
                option.label = device.label;
                option.setAttribute("data-id", id);
                if (isActive) {
                    option.setAttribute("selected", "selected");
                }
                selectEl.appendChild(option);
            });
        }

        function updateAllAudioDevices() {
            if (device) {
                updateDevices(speakerDevices, device.audio.speakerDevices.get());
                updateDevices(ringtoneDevices, device.audio.ringtoneDevices.get());
            }
        }

        async function getAudioDevices() {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            updateAllAudioDevices.bind(device);
        }

        function updateOutputDevice() {
            const selectedDevices = Array.from(speakerDevices.children)
                .filter((node) => node.selected)
                .map((node) => node.getAttribute("data-id"));
            device.audio.speakerDevices.set(selectedDevices);
        }

        function updateRingtoneDevice() {
            const selectedDevices = Array.from(ringtoneDevices.children)
                .filter((node) => node.selected)
                .map((node) => node.getAttribute("data-id"));

            device.audio.ringtoneDevices.set(selectedDevices);
        }

        function bindVolumeIndicators(call) {
            call.on("volume", function (inputVolume, outputVolume) {
                let inputColor = "red";
                if (inputVolume < 0.5) {
                    inputColor = "green";
                } else if (inputVolume < 0.75) {
                    inputColor = "yellow";
                }

                inputVolumeBar.style.width = `${Math.floor(inputVolume * 300)}px`;
                inputVolumeBar.style.background = inputColor;

                let outputColor = "red";
                if (outputVolume < 0.5) {
                    outputColor = "green";
                } else if (outputVolume < 0.75) {
                    outputColor = "yellow";
                }

                outputVolumeBar.style.width = `${Math.floor(outputVolume * 300)}px`;
                outputVolumeBar.style.background = outputColor;
            });
        }

        // MISC USER INTERFACE

        // Activity log
        function log(message) {
            logDiv.innerHTML += `<p class="log-entry">&gt;&nbsp; ${message} </p>`;
            logDiv.scrollTop = logDiv.scrollHeight;
        }

        window.outgoingCallHandler = null;

        function resetIncomingCallUI() {
            incomingPhoneNumberEl.innerHTML = "";
            incomingCallAcceptButton.classList.remove("hide");
            incomingCallRejectButton.classList.remove("hide");
            incomingCallHangupButton.classList.add("hide");
            incomingCallDiv.classList.add("hide");
            outgoingCallDiv.classList.add("hide");
            document.getElementById("incoming-mute-button").classList.add("hide");
        }

        function updateUIAcceptedOutgoingCall(call) {
            log("Call in progress ...");
            callButton.disabled = true;
            outgoingCallHangupButton.classList.remove("hide");
            volumeIndicators.classList.remove("hide");
            document.getElementById("outgoing-mute-button").classList.remove("hide");
            bindVolumeIndicators(call);
        }

        function updateUIDisconnectedOutgoingCall() {
            log("Call disconnected.");
            callButton.disabled = false;
            outgoingCallHangupButton.classList.add("hide");
            volumeIndicators.classList.add("hide");
            outgoingCallDiv.classList.add("hide");
            document.getElementById("outgoing-mute-button").classList.add("hide");
        }

        // ACCEPT INCOMING CALL

        // HANDLE INCOMING CALL
        let handledIncomingCalls = [];

        function handleIncomingCall(call) {
            let sid = call.parameters.CallSid;
            console.log(call.parameters);
            log(`Incoming call from ${call.parameters.From}`);

            // only if available
            let willIgnore = true;
            if (window.showCallingUser) {
                if (handledIncomingCalls.includes(sid) === false) {
                    if (document.getElementById('ddlStatus').value === 'available') {
                        //only available people will receive calls     
                        willIgnore = false;
                    }
                }
            }

            if (willIgnore) {
                console.log('going to receive the call.');
                return;
            }

            window.isMuted = call.isMuted;

            window.mute = (val) => {
                call.mute(val);
            };


            if (window.showCallingUser) {
                if (handledIncomingCalls.includes(sid) === false) {
                    try {
                        console.log("incoming call (x): sid" + sid);
                        handledIncomingCalls.push(sid);
                        window.showCallingUser({
                            type: "incoming",
                            sid: sid,
                            calledNumber: call.parameters.To,
                            number: call.parameters.From,
                        });
                    } catch (e) {
                        console.log('error on showing incoming caller.');
                    }
                }
            }


            // show incoming call div and incoming phone number
            incomingCallDiv.classList.remove("hide");
            outgoingCallDiv.classList.add("hide");
            incomingPhoneNumberEl.innerHTML = call.parameters.From;
            outgoingCallDiv.classList.add("hide");

            // add event listeners for Accept, Reject, and Hangup buttons
            incomingCallAcceptButton.onclick = () => {
                setOnCall(true);

                call.accept();

                // update UI
                log("Accepted incoming call.");
                incomingCallAcceptButton.classList.add("hide");
                incomingCallRejectButton.classList.add("hide");
                incomingCallHangupButton.classList.remove("hide");
                document.getElementById("incoming-mute-button").classList.remove("hide");

                outgoingCallDiv.classList.add("hide");
            };

            incomingCallRejectButton.onclick = () => {
                setOnCall(false);

                call.reject();
                log("Rejected incoming call");
                resetIncomingCallUI();
                outgoingCallDiv.classList.remove("hide");
            };

            incomingCallHangupButton.onclick = () => {
                setOnCall(false);

                call.disconnect();
                log("Hanging up incoming call");
                resetIncomingCallUI();
                outgoingCallDiv.classList.remove("hide");
            };

        }

        // MAKE AN OUTGOING CALL
        async function makeOutgoingCall(propertyId) {
            outgoingCallDiv.classList.remove("hide");
            const params = {
                To: phoneNumberInput.value,
                PropertyId: propertyId
            };

            if (device) {
                setOnCall(true);

                log(`Attempting to call ${params.To} ...`);
                outgoingCallHangupButton.classList.remove("hide");
                const call = await device.connect({ params });
                window.isMuted = call.isMuted;
                window.mute = (val) => {
                    call.mute(val);
                };

                call.on("accept", (call) => {
                    if (call.parameters.CallSid && window.outgoingCallHandler) {
                        window.outgoingCallHandler(call.parameters.CallSid);
                    }
                    updateUIAcceptedOutgoingCall(call);
                });

                call.on("disconnect", () => {
                    console.log("call disconnected");
                    console.log(call);
                    call.disconnect();
                    updateUIDisconnectedOutgoingCall();
                    setOnCall(false);
                });

                call.on("cancel", () => {
                    console.log("call cancelled");
                    call.disconnect();
                    updateUIDisconnectedOutgoingCall();
                    setOnCall(false);
                });

                call.on("error", (error) => {
                    console.log("An error has occurred: ", error);
                    setOnCall(false);
                });

                call.on("reject", () => {
                    console.log("call rejected");
                    call.disconnect();
                    updateUIDisconnectedOutgoingCall();
                    setOnCall(false);
                });

                outgoingCallHangupButton.onclick = () => {
                    log("Hanging up ...");
                    call.disconnect();
                    setOnCall(false);
                };

            } else {
                log("Unable to make call.");
            }
        }

        function addDeviceListeners(device) {
            device.on("registered", function () {
                log("Twilio.Device Ready to make and receive calls!");
                //outgoingCallDiv.classList.remove("hide");
                eventLogDiv.classList.remove("hide");
            });

            device.on("error", function (error) {
                log(`Twilio.Device Error: ${error.message}`);
                document.getElementById("controls").classList.add("hide");
                document.getElementById("startup-button").classList.remove("hide");
            });

            device.on("incoming", handleIncomingCall);

            device.audio.on("deviceChange", updateAllAudioDevices.bind(device));

            // Show audio selection UI if it is supported by the browser.
            if (device.audio.isOutputSelectionSupported) {
                audioSelectionDiv.classList.remove("hide");
            }
        }

        function initializeDevice() {
            logDiv.classList.remove("hide");
            log("Initializing device");
            // eslint-disable-next-line no-undef
            device = new Twilio.Device(accessToken, {
                debug: true,
                answerOnBridge: true,
                /*
                 * Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
                 * providing better audio quality in restrained network conditions. Opus will be default in 2.0.
                 */
                codecPreferences: ["opus", "pcmu"],
            });

            //  SETUP STEP 4: Listen for Twilio.Device states
            addDeviceListeners(device);

            // Device must be registered in order to receive incoming calls
            device.register();
            startupButton.classList.add("hide");
            document.getElementById("controls").classList.remove("hide");
        }

        /*
         * SETUP STEP 1:
         * Browser client should be started after a user gesture
         * to avoid errors in the browser console re: AudioContext
         */

        function startupClient() {
            // let graphAccessToken = localStorage.getItem("graphAccessToken");

            fetch(TWILIO_TOKEN_URL, {
                headers: {
                    "content-type": "application/json",
                    "x-karya-token": localStorage.getItem("token"),
                    // Authorization: "Bearer " + graphAccessToken,
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw "an error on creating phone";
                    }
                })
                .then((res) => {
                    const { token, identity } = res;
                    log("Got a token.");
                    accessToken = token;
                    // SETUP STEP 3: Instantiate a new Twilio.Device
                    try {
                        initializeDevice();
                    } catch (e) {
                        alert(
                            "An error occurred. See your browser console for more information."
                        );
                    }
                })
                .catch(() => {
                    console.log("an error occured while getting twilio token");
                });
        }

        startupButton.addEventListener("click", startupClient);

        // Event Listeners

        callButton.onclick = (e) => {
            e.preventDefault();
            const propertyId = e.target.getAttribute("property-id");
            makeOutgoingCall(propertyId);
        };

        getAudioDevicesButton.onclick = getAudioDevices;
        speakerDevices.addEventListener("change", updateOutputDevice);
        ringtoneDevices.addEventListener("change", updateRingtoneDevice);

        document.getElementById("initTwilioContainer").style.display = "block";
        document.body.classList.add("phoneActive");

        //let muteButtons = document.getElementsByClassName("mute-button");

        const handleMute = (button) => {
            if (window["mute"]) {
                try {
                    window.isMuted = !window.isMuted;
                    window.mute(window.isMuted);
                    button.innerText = window.isMuted ? "Unmute" : "Mute";
                    button.classList.remove("do-mute");
                    button.classList.remove("do-unmute");
                    button.classList.add(window.isMuted ? "do-unmute" : "do-mute");
                } catch (e) {
                    console.log(e);
                }
            }
        };

        document.getElementById("outgoing-mute-button").onclick = () => {
            let button = document.getElementById("outgoing-mute-button");
            handleMute(button);
        };

        document.getElementById("incoming-mute-button").onclick = () => {
            let button = document.getElementById("incoming-mute-button");
            handleMute(button);
        };

        startupClient();

        fetch(config.apiURL + "/availability", {
            headers: {
                "content-type": "application/json",
                "x-karya-token": localStorage.getItem("token"),
            },
            method: "GET",
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((availabilityStatus) => {
                let control = document.getElementById("ddlStatus");
                control.value = availabilityStatus;
                document
                    .getElementById("initTwilioContainer")
                    .classList.remove("unavailable");
                if (availabilityStatus !== "available") {
                    document
                        .getElementById("initTwilioContainer")
                        .classList.add("unavailable");
                }

                control.onchange = (sender) => {
                    let value = document.getElementById("ddlStatus").value;
                    document
                        .getElementById("initTwilioContainer")
                        .classList.remove("unavailable");

                    fetch(config.apiURL + "/availability/" +
                        value,
                        {
                            headers: {
                                "content-type": "application/json",
                                "x-karya-token": localStorage.getItem("token"),
                            },
                            method: "POST",
                        }
                    ).then((res) => {
                        if (res.ok) {
                            if (value !== "available") {
                                document
                                    .getElementById("initTwilioContainer")
                                    .classList.add("unavailable");
                            }

                            let durationInSeconds = 2;
                            window["vt"].custom(
                                "Your status has been changed",
                                {
                                    duration: durationInSeconds * 1000,
                                    fadeDuration: durationInSeconds * 1000,
                                    position: "top-right",
                                },
                                "success"
                            );
                        }
                    });
                };
            });
    }

    useEffect(() => {
        InitTwilio();
    }, []);

    return (
        <div id="initTwilioContainer" className="sidePhone">
           
            <div>
                <div>
                    <button id="startup-button" style={{width:'100%'}} class="btn btn-warning mb-2 btn-xs">Activate Phone</button>
                </div>
                <main id="controls" className="hide">
                    <section className="left-column" id="info">
                        <div id="output-selection" className="hide">
                            <label>Microphones</label>
                            <select className="form-control" id="ringtone-devices" multiple></select>
                            <label>Speakers</label>
                            <select className="form-control" id="speaker-devices" multiple></select>
                            <br />
                            <button id="get-devices" className="btn btn-xs">Seeing "Unknown" devices?</button>

                            <div className="mt-2">
                                Status:
                                <select id="ddlStatus" className="form-control input input-sm">
                                    <option value={"available"}>Available</option>
                                    <option value={"not-available"}>Not Available</option>
                                </select>
                            </div>
                        </div>
                    </section>
                    <section className="center-column">
                        <div>
                            <div id="outgoing-call" className="hide">
                                <h2 className="make-a-call-label">Make a Call</h2>
                                <form>
                                    <label style={{ fontSize: "12px" }}>
                                        Use call button in tenant's Communications
                                    </label>
                                    <input
                                        className="mt-3"
                                        id="phone-number"
                                        type="text"
                                        disabled
                                        placeholder="+15552221234"
                                    />
                                    <button id="button-call" className="mt-2" type="submit">
                                        Call
                                    </button>
                                </form>

                                <div className="row">
                                    <div className="col-6 text-left">
                                        <button
                                            id="outgoing-mute-button"
                                            className="mute-button do-mute btn btn-xs mt-3 hide"
                                        >
                                            Mute
                                        </button>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            id="button-hangup-outgoing"
                                            className="hide btn btn-xs mt-3 btn-danger"
                                        >
                                            Hang Up
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div id="incoming-call" className="hide">
                                <div className="instructions">
                                    Incoming Call:
                                    <div id="incoming-number"></div>
                                </div>

                                <div className="row">
                                    <div className="col-6 text-left">
                                        <button
                                            id="button-accept-incoming"
                                            className="btn btn-xs mt-3 btn-success"
                                        >
                                            Accept
                                        </button>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            id="button-reject-incoming"
                                            className="btn btn-xs mt-3 btn-danger"
                                        >
                                            Reject
                                        </button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6 text-left">
                                        <button
                                            id="incoming-mute-button"
                                            className="mute-button do-mute btn btn-xs mt-3 hide"
                                        >
                                            Mute
                                        </button>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button
                                            id="button-hangup-incoming"
                                            className="btn btn-xs mt-3 btn-danger hide"
                                        >
                                            Hangup
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div id="volume-indicators" className="hide mt-2">
                                <label>Mic Volume</label>
                                <div id="input-volume"></div>
                                <br />
                                <br />
                                <label>Speaker Volume</label>
                                <div id="output-volume"></div>
                            </div>
                        </div>
                    </section>
                    <section
                        id="eventLogDiv"
                        className="event-log hide"
                        style={{ display: "none" }}
                    >
                        <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                        >
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne"
                                    >
                                        Event Log
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div className="accordion-body">
                                        <div className="hide" id="log"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>

    )
}

export default SidePhone;