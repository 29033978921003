const FollowUpLister = (props: any) => {
  const followUps = props.followUps;
  return (
    <table className="table table-striped table-v-middle table-aligned-left">
      <thead>
        <tr>
          <th>Property</th>
          <th>Person</th>
          <th>Type</th>
          <th>Follow Up Date</th>
          <th>Follow Up Message</th>
          <th>Last Contacted</th>
          <th>Last Update</th>
        </tr>
      </thead>
      <tbody>
        {followUps.length === 0 && (
          <tr>
            <td colSpan={7} className="text-center">
              No follow ups found
            </td>
          </tr>
        )}
        {followUps.map((followUp: any, ndx: number) => (
          <tr key={ndx}>
            <td>{followUp.PropertyName}</td>
            <td>
              <button
                className="btn btn-link btn-name"
                onClick={() => props.showUser(followUp.Id)}
              >
                {followUp.Name || "Unknown Person"}
              </button>
            </td>
            <td>{followUp.Type === "lead" ? "Lead" : "Not Lead"}</td>
            <td>{followUp.FollowUpDate}</td>
            <td>{followUp.Message}</td>
            <td>{followUp.LastContact}</td>
            <td>{followUp.LastUpdate}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FollowUpLister;
