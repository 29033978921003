import { snackError } from "../Lead/Lead";
import { localDate } from "../Util";
import config from "../config";
const Voicemails = (props: any) => {

  const deleteVoicemail = (id: number) => {
    fetch(config.apiURL + "/voicemail/" + id, {
      method: "DELETE",
      headers: {
        "x-karya-token": localStorage.getItem("token")!,
        "content-type": "application/json",
      },
    }).then((res) => {
      if (res.ok) {
        let v = props.voicemails.findIndex((x:any) => x.Id === id);
        let clone = [...props.voicemails];
        clone.splice(v, 1);
        props.setVoicemails(clone);
      }
    });
  };


  const playRecording = (event: any, voicemailItem: VoicemailItem) => {
    fetch(config.apiURL + "/recording/" + voicemailItem.RecordingSid, {
      headers: {
        "x-karya-token": localStorage.getItem("token")!,
      }
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          snackError("An error occured while getting the audio file.");
        }
      })
      .then((x) => {
        event.target.parentNode.innerHTML = `
        <audio controls style='width:100%'>
          <source src='${x}' type="audio/mpeg"></source>
        </audio>`;

        fetch(config.apiURL + "/voicemail/" + voicemailItem.Id, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "x-karya-token": localStorage.getItem("token")!,
          },
        }).then((vmRes) => {
          if (vmRes.ok) {
            return Promise.resolve(1);
          }
        });
      });
  };

  return (
    <table className="table table-striped v-middle">
      <thead>
        <tr>
          <th>Date</th>
          <th>Caller</th>
          <th>Matched Record</th>
          <th style={{ width: "300px" }}>Listen</th>
          <th>Is Listened</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {props.voicemails.map((row:any, ndx: number) => (
          <tr className={row.Listened ? "" : "new-voicemail"}>
            <td>{localDate(row.Date)}</td>
            <td>{row.Caller}</td>
            <td>
              {row.MatchedId && (
                <div>
                  <button
                    className="btn btn-link btn-name"
                    onClick={() => props.showUser(row.MatchedId)}
                  >
                    {row.MatchedName}
                  </button>
                </div>
              )}
            </td>
            <td>
              <div>
                <button
                  onClick={(event) => playRecording(event, row)}
                  className="btn btn-xs btn-primary"
                >
                  Play
                </button>
              </div>
            </td>
            <td>{row.Listened ? "Yes" : "No"}</td>
            <td>
              <button
                onClick={() => deleteVoicemail(row.Id)}
                className="btn btn-xs btn-danger"
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        ))}

        {props.voicemails.length == 0 && <tr>
          <td colSpan={6} className="text-center">No notifications found</td>
        </tr>}
      </tbody>
    </table>
  );
};
export default Voicemails;
interface VoicemailItem {
  Id: number;
  Date: Date;
  Caller: String;
  RecordingSid: String;
  MatchedId: string;
  MatchedName: string;
  Listened: Boolean;
}
