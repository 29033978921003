function RoomInfo(props) {
  const room = props.room;
  return (
    <div className="text-left room-info">
      <div className="row">
        <div className="col-md-4">
          <b>Name:&nbsp;</b>
          <a href={`/lead/${room.leadId}`} target="_blank" rel="noreferrer">{room.creator?.name}</a>
        </div>
        <div className="col-md-4">
          <b>Email:&nbsp;</b>
          {room.creator?.email}
        </div>
        <div className="col-md-4">
          <b>Phone:&nbsp;</b>
          {room.creator?.phone}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <b>Type:&nbsp;</b>
          {room.creator?.roomType}
        </div>
        <div className="col-md-4">
          <b>Call Type:&nbsp;</b>
          {room.creator?.type}
        </div>
        <div className="col-md-4">
          <b>Unit:&nbsp;</b>
          {room.creator?.unit}
        </div>
      </div>
      <div>
        <b>Message:</b>
        <div>
        {room.creator?.message}

        </div>
      </div>
    </div>
  );
}

export default RoomInfo;
