import Modal from "../Modal/Modal";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import config from "../config";
import LoadingContext from "../LoadingContext";
import "./Lead.css";
import Rooms from "../Rooms/Rooms";
import { useParams } from "react-router-dom";
import LeadAppointments from "../Appointments/LeadAppointments";
import TagsInput from "../Tags/Tags";
import NotesAndFollowUps from "../NotesAndFollowUps/NotesAndFollowUps";
import { localDate, utcNOW, cleanDBPhone, getMinutesPassed } from "../Util";
function Lead(props: any) {
  useParams();
  let { leadId } = useParams();

  const [detailEmail, setDetailEmail] = useState(null as Contact | null);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [userState, setUserState] = useState<DataItem>(null as any);
  const [dirty, setDirty] = useState(false);
  const [messageMode, setMessageMode] = useState(
    null as "Email" | "Text" | null
  );
  const [activeReply, setActiveReply] = useState(null as Contact | null);
  const [selectedPhone, setSelectedPhone] = useState(
    null as PhoneRecord | null
  );

  const messageContentRef = useRef<HTMLTextAreaElement | null>(null);
  const subjectContentRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  let saveButtonRef = useRef<HTMLButtonElement | null>(null);
  const { setLoading } = useContext(LoadingContext)!;
  const newPhoneRef = useRef<HTMLInputElement | null>(null);
  const newPhoneNameRef = useRef<HTMLInputElement | null>(null);
  const owners = ["Alper", "Izel"];

  useEffect(() => {
    //set property id for the call
    if (props.user) {
      setUserState(props.user);
      const callButton = document.getElementById("button-call");
      callButton?.setAttribute("property-id", props.user.PropertyId.toString());
    } else {
      fetch(config.apiURL + "/item/" + leadId, {
        headers: {
          "x-karya-token": localStorage.getItem("token")!,
        },
      }).then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          setUserState(data);
          const callButton = document.getElementById("button-call");
          callButton?.setAttribute("property-id", data.PropertyId.toString());
        } else {
          snackError("An error occured while fetching the lead.");
        }
      });
    }
  }, []);

  const showEmailContent = (i: Contact, event: any) => {
    setDetailEmail(i);
    //event.target.parentNode.innerHTML = i.emailDetails?.content;
  };

  const playRecording = (event: any, recordingId: string) => {
    fetch(config.apiURL + "/recording/" + recordingId, {
      headers: {
        "x-karya-token": localStorage.getItem("token")!,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          snackError("An error occured while getting the audio file.");
        }
      })
      .then((x) => {
        event.target.parentNode.innerHTML = `
        <audio controls style='flex: auto; min-width:600px'>
          <source src='${x}' type="audio/mpeg"></source>
        </audio>`;
      });
  };

  const deletePhoneHandler = (phoneIndex: number) => {
    let phones = userState.CellPhones;
    phones.splice(phoneIndex, 1);
    setUserState((curr: any) => ({
      ...curr,
      CellPhones: phones,
    }));
    setDirty(true);
  };

  const doCall = () => {
    let wnd = window as any;
    let callTo = selectedPhone!.phone;

    wnd.outgoingCallHandler = (callSid: string) => {
      let req: Contact = {
        content: "",
        createdOn: utcNOW(),
        emailDetails: null,
        sid: callSid,
        isOutbound: true,
        to: callTo,
        id: null,
        from: null,
        type: "Phone",
        recordingId: null,
        createdBy: null,
        followUpDate: null,
        followUpDone: null,
      };
      fetch(config.apiURL + "/item/" + userState.Id + "/call", {
        headers: {
          "content-type": "application/json",
          "x-karya-token": localStorage.getItem("token")!,
        },
        method: "POST",
        body: JSON.stringify(req),
      })
        .then(async (res) => {
          if (res.ok) {
            const record = await res.json();
            let clone = { ...userState };
            clone.Communications.unshift(record);
            setUserState(clone);
          } else {
            snackError("Error on call save: " + res.status);
          }
        })
        .finally(() => {});
    };

    let controlsDiv = document.getElementById("controls");
    if (controlsDiv) {
      let canCall = controlsDiv.classList.contains("hide") === false;
      if (canCall) {
        let phoneNumberInput: any = document.getElementById("phone-number");
        if (phoneNumberInput) {
          phoneNumberInput.value = selectedPhone?.phone;
        }
        let callButton = document.getElementById("button-call");
        if (callButton) {
          callButton.click();
        }
      } else {
        snackError("Phone needs to be activated");
      }
    }
  };

  const deleteRecording = (index: number, sid: string) => {
    if (
      window.confirm(
        "Are you sure you want to delete this record and the recording?"
      )
    ) {
      fetch(config.apiURL + "/item/" + userState.Id + "/recording/" + sid, {
        headers: {
          "content-type": "application/json",
          "x-karya-token": localStorage.getItem("token")!,
        },
        method: "DELETE",
      }).then((x) => {
        if (x.ok) {
          let clone = { ...userState };
          clone.Communications.splice(index, 1);
          setUserState(clone);
          return Promise.resolve();
        }
      });
    }
  };

  const addNewPhone = () => {
    if (newPhoneRef?.current?.value) {
      if (newPhoneNameRef?.current?.value) {
        let phones = userState.CellPhones;
        phones.push({
          name: newPhoneNameRef.current.value,
          phone: cleanDBPhone(newPhoneRef.current.value),
        });
        setUserState((curr: any) => ({
          ...curr,
          CellPhones: phones,
        }));
        newPhoneNameRef.current.value = "";
        newPhoneRef.current.value = "";
        setDirty(true);
        return;
      }
    }
    snackError("Please enter both name and phone number.");
  };

  const fetchCommunication = useCallback(async () => {
    //await GetRefreshToken();
    fetch(config.apiURL + "/item/" + userState.Id + "/communication", {
      headers: {
        "content-type": "application/json",
        //"x-ms-access-token": localStorage.getItem("graphAccessToken")!,
        "x-karya-token": localStorage.getItem("token")!,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          console.log("error on fetching comms");
        }
      })
      .then((res) => {
        let error: string[] = [];
        if (res.email === false) {
          error.push("Emails");
        }
        if (res.sms === false) {
          error.push("Text Messages");
        }
        if (error.length > 0) {
          snackError("Error occured fetching: " + error.join(","));
        }

        let data = res.data;
        if (data) {
          //let clone = { ...userState };
          // data.forEach((it: any) => {
          //   if (it.createdOn) {
          //     it.createdOn = new Date(it.createdOn);
          //   }
          // });

          if (
            JSON.stringify(data) !== JSON.stringify(userState.Communications)
          ) {
            let clone = { ...userState };
            clone.Communications = data;
            setUserState(clone);
          }
        }
      });
  }, []);

  const closeEmailDetailModal = () => {
    setDetailEmail(null);
  };

  const handlePhoneChange = (event: any) => {
    let index = +event.target.value;
    if (index === -1) {
      setSelectedPhone(null);
    } else {
      let phone = userState.CellPhones[index];
      setSelectedPhone(phone);
    }
  };

  const doSave = (item: DataItem) => {
    if (saveButtonRef?.current) saveButtonRef.current!.disabled = true;

    setLoading(true);

    return new Promise((succ, err) => {
      let req = {
        Id: item.Id,
        Email: item.Email,
        CellPhones: item.CellPhones,
        Owner: item.Owner,
        Status: item.Status,
        Tags: item.Tags,
        ExpectedMoveInDate: item.ExpectedMoveInDate,
      };
      fetch(config.apiURL + "/item/" + item.Id, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "x-karya-token": localStorage.getItem("token")!,
        },
        body: JSON.stringify(req),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res.status;
          }
        })
        .then(() => {
          setDirty(false);
          snackSuccess("Item has been updated successfully");
          setUserState(item);
          succ(true);
        })
        .catch(() => {
          snackError("An error occured while saving the item");
          succ(false);
        })
        .finally(() => {
          setLoading(false);
          if (saveButtonRef?.current) saveButtonRef.current!.disabled = false;
        });
    });
  };

  const save = () => {
    doSave(userState).then(() => {});
  };

  const doSendMessage = async () => {
    setLoading(true);
    if (messageMode === "Email") {
      doSave(userState).then(() => {
        //save first

        const formData = new FormData();
        const files = emailRef!.current!.files!;
        for (let i = 0; i < files.length; i++) {
          formData.append("files[]", files[i]);
        }
        formData.append("content", messageContentRef.current!.value);
        formData.append("subject", subjectContentRef!.current!.value);
        formData.append("email", userState.Email);
        if (activeReply) {
          formData.append("activeReply", JSON.stringify(activeReply));
        }

        fetch(config.apiURL + "/item/" + userState.Id + "/email", {
          headers: {
            //"content-type": "application/json",
            "x-karya-token": localStorage.getItem("token")!,
          },
          method: "POST",
          body: formData,
          // body: JSON.stringify({
          //   activeReply: activeReply,
          //   content: messageContentRef.current!.value,
          //   subject: subjectContentRef!.current!.value,
          //   email: userState.Email,
          // }),
        })
          .then((res) => {
            if (res.ok) {
              setShowSendMessageModal(false);
              setActiveReply(null);
              snackSuccess("Email has been sent");
              fetchCommunication().then(() => {});
            } else {
              throw res.status;
            }
          })
          .catch((err) => {
            snackError("An error occured while sending the e-mail");
          })
          .finally(() => {
            setLoading(false);
          });
      });
    } else {
      fetch(config.apiURL + "/item/" + userState.Id + "/sms", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "x-karya-token": localStorage.getItem("token")!,
        },
        body: JSON.stringify({
          content: messageContentRef.current?.value,
          to: selectedPhone?.phone,
        }),
      })
        .then(async (res) => {
          if (res.ok) {
            const record = await res.json();
            let clone = { ...userState };
            clone.Communications.unshift(record);
            setUserState(clone);
            setShowSendMessageModal(false);
            snackSuccess("Text message have been sent");
          } else {
            snackError("An error occured while sending the text message");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const showSendMessage = (mode: "Email" | "Text", replyTo: any = null) => {
    if (detailEmail) {
      setDetailEmail(null);
    }
    setActiveReply(replyTo);
    setShowSendMessageModal(true);
    setMessageMode(mode);
  };

  const setField = (field: string, value: any) => {
    setUserState((curr: any) => ({
      ...curr,
      [field]: value,
    }));
    setDirty(true);
  };
  const anchor = (e: any) => {
    e.preventDefault();
    const href = e.target.getAttribute("href");
    const elem = document.getElementById(href.replace("#", ""));
    const modals = document.getElementsByClassName("modal");
    const hasModal = modals.length > 0;
    if (hasModal) {
      let yCoordinate = elem!.getBoundingClientRect().top + modals[0].scrollTop - 75;
      if (yCoordinate < 0) yCoordinate = 0;
      modals[0].scrollTo({ top: yCoordinate, behavior: "smooth" });
    } else {
      let yCoordinate = elem!.getBoundingClientRect().top + window.scrollY - 75;
      if (yCoordinate < 0) yCoordinate = 0;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };
  const render_data = userState && (
    <div className="lead-container">
      {dirty && (
        <div className="row save-changes-warning mb-4">
          <div className="col-6">Don't forget to save your changes.</div>
          <div className="col-6 text-right">
            <button
              className="btn btn-sm btn-success"
              ref={saveButtonRef}
              onClick={save}
            >
              Save Changes
            </button>
          </div>
        </div>
      )}

      <div id="left-menu-container-parent">
        <div id="left-menu-container">
          <a onClick={anchor} href="#personal_information">
            Personal Information
          </a>

          <a onClick={anchor} href="#notes_and_follow_up">
            Notes & Follow Up
          </a>

          <a onClick={anchor} href="#appointments">
            Appointments
          </a>

          <a onClick={anchor} href="#rooms">
            Rooms
          </a>

          <a onClick={anchor} href="#communication">
            Communication
          </a>
        </div>
        <div id="lead-info-start">
          <div className="card" id="personal_information">
            <div className="card-header">Personal Information</div>
            <div className="card-body">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(7, 1fr)",
                  gridTemplateRows: "repeat(2, 50px)",
                  gap: "20px",
                }}
              >
                <div>
                  <div>
                    <label>Unit</label>
                    <div>{userState.Unit || "-"}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <label>Family Size</label>
                    {!userState.FamilySize && "-"}
                    {userState.FamilySize > 0 && userState.FamilySize}
                  </div>
                </div>
                <div>
                  <label>Residency Status</label>
                  {userState.IsCurrentResident
                    ? "Current Resident"
                    : "Moved Out"}
                </div>

                <div>
                  <label>Marketing Channel</label>
                  {userState.MarketingChannel || "-"}
                </div>
                <div>
                  <label>Lease Term</label>
                  {userState.LeaseTerm || "-"}
                </div>

                <div>
                  <label>Leasing agent</label>
                  {!userState.EmployeeName && "-"}
                  {userState.EmployeeName === "Invalid Lookup"
                    ? "-"
                    : userState.EmployeeName}
                </div>
                <div>
                  <label>Eviction Started</label>
                  {userState.IsEvictionProceedingsStarted ? "Yes" : "-"}
                </div>
                <div>
                  <label>Lease Start</label>
                  {!userState.LeaseBeginDate
                    ? "-"
                    : localDate(userState.LeaseBeginDate, false)}
                </div>
                <div>
                  <label>Lease End</label>
                  {!userState.LeaseEndDate
                    ? "-"
                    : localDate(userState.LeaseEndDate, false)}
                </div>
                <div>
                  <label>Notice to Vacate</label>
                  {!userState.NoticeToVacateDate
                    ? "-"
                    : localDate(userState.NoticeToVacateDate, false)}
                </div>
                <div>
                  <label>Last Contact</label>
                  {localDate(userState.LastContact)}
                </div>
                <div>
                  <label>Term Rent</label>
                  {userState.TermRent && "$" + userState.TermRent}
                  {!userState.TermRent && "-"}
                </div>

                <div>
                  <label>Actual Move Out</label>
                  {!userState.ActualMoveOutDate && "-"}
                  {localDate(userState.ActualMoveOutDate, false)}
                </div>

                <div>
                  <label>Balance</label>${userState.EndBalance}
                </div>

                {/* <div className="col">
              <label>Paid</label>${userState.TotalPaid ?? 0}
            </div> */}
              </div>

              <div className="gray-form-container mt-4">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <div style={{ display: "flex" }}>
                      <div className="mr-2">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          defaultValue={userState.Email}
                          onChange={(event) =>
                            setField("Email", event.target.value)
                          }
                        />
                      </div>

                      <div>
                        <label>Expected Move In</label>
                        <input
                          onChange={(e) =>
                            setField("ExpectedMoveInDate", e.target.value)
                          }
                          style={{ width: "150px" }}
                          className="form-control input input-sm"
                          type="date"
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 text-center">
                    <div className="form-group">
                      <label>Phone</label>
                      <input
                        type="text"
                        style={{ width: "120px", display: "inline-block" }}
                        className="form-control mr-2"
                        placeholder="Name"
                        ref={newPhoneNameRef}
                      />
                      <input
                        type="tel"
                        style={{ width: "150px", display: "inline-block" }}
                        className="form-control mr-2"
                        placeholder="Number"
                        ref={newPhoneRef}
                      />
                      <button
                        className="fa fa-add fa-btn btn-primary ml-2"
                        onClick={addNewPhone}
                      ></button>
                      {userState.CellPhones.map(
                        (i: PhoneRecord, phoneIndex: number) => (
                          <div className="mt-2 existing-phone-record">
                            <span className="mr-2">
                              {" "}
                              {i.name} - {i.phone}
                            </span>
                            <button
                              className="fa fa-trash fa-btn btn-outline-danger ml-2"
                              onClick={() => deletePhoneHandler(phoneIndex)}
                            ></button>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="mr-2  text-center">
                        <label>Owner</label>
                        <div
                          style={{
                            width: "145px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <select
                            onChange={(event) =>
                              setField('Owner', event.target.value || null)
                            }
                            className="form-control"
                          >
                            <option key={0} value={""}>
                              Not Assigned
                            </option>
                            {owners.map((own: string, ownerIndex: number) => (
                              <option
                                selected={userState.Owner === own}
                                key={ownerIndex + 1}
                                value={own}
                              >
                                {own}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="text-center">
                        <label>Status</label>
                        <select
                          className="form-control input input-sm"
                          onChange={(e) => setField("Status", e.target.value)}
                        >
                          <option value="In Progress">In Progress</option>
                          <option value="Scheduled Appointment">
                            Scheduled Appointment
                          </option>
                          <option value="Following Up">Following Up</option>
                          <option value="Cold">Cold</option>
                          <option value="Lost">Lost</option>
                          <option value="Leased">Leased</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <TagsInput
                    placeholder={"Person tags"}
                    data={userState.Tags}
                    onChange={(val: string[]) => setField("Tags", val)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4" id="notes_and_follow_up">
            <div
              className="card-header"
              style={{
                background: "rgb(84 84 84)",
                color: "white",
              }}
            >
              Notes & Follow Up
            </div>
            <div className="card-body">
              <NotesAndFollowUps
                userState={userState}
                setUserState={setUserState}
              />
            </div>
          </div>

          <div className="card mt-4" id="appointments">
            <div
              className="card-header"
              style={{
                background: "rgb(67 69 130)",
                color: "white",
              }}
            >
              Appointments
            </div>
            <div className="card-body">
              <LeadAppointments leadId={userState.Id} />
            </div>
          </div>

          <div className="card mt-4" id="rooms">
            <div
              className="card-header"
              style={{ background: "rgb(4 119 16)", color: "white" }}
            >
              Rooms
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <Rooms hidePersonColumn={true} leadId={userState.Id} />
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4 mb-4" id="communication">
            <div
              className="card-header"
              style={{
                background: "#032c94",
                color: "white",
              }}
            >
              Communication
            </div>
            <div className="card-body">
              <div className="gray-form-container call-button-container">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    style={{ width: "250px", display: "inline-block" }}
                    className="form-control mr-2"
                    onChange={handlePhoneChange}
                  >
                    <option value={-1}>Select Phone</option>
                    {userState.CellPhones.map(
                      (p: PhoneRecord, pIndex: number) => (
                        <option key={pIndex} value={pIndex}>
                          {p.name} - {p.phone}
                        </option>
                      )
                    )}
                  </select>

                  <button
                    className="btn mr-2 btn-outline-primary"
                    disabled={!selectedPhone}
                    onClick={() => showSendMessage("Text")}
                  >
                    Send Text Message
                  </button>

                  <button
                    className="btn mr-2 btn-outline-success"
                    disabled={!selectedPhone}
                    onClick={doCall}
                  >
                    Call
                  </button>

                  <button
                    className="btn mr-2 btn-outline-info"
                    onClick={() => showSendMessage("Email")}
                  >
                    Send Email
                  </button>
                </div>
              </div>

              <table className="table appointment-table table-striped table-aligned-left mt-4">
                <thead>
                  <tr>
                    <th style={{ width: "200px" }}>Date</th>
                    <th style={{ width: "200px" }}>Type</th>
                    <th>Content</th>
                  </tr>
                </thead>
                <tbody>
                  {userState.Communications.length === 0 && (
                    <tr className="italic">
                      <td colSpan={3}>No communication has been recorded.</td>
                    </tr>
                  )}
                  {userState.Communications.map((i: any, cIndex: number) => (
                    <tr key={cIndex}>
                      <td valign="top">
                        <div style={{ fontSize: "12px" }}>
                          {localDate(i.createdOn)}
                        </div>
                      </td>
                      <td valign="top" style={{ fontSize: "12px" }}>
                        <div>
                          <i
                            className={`${
                              i.isOutbound
                                ? "fa-solid fa-circle-right"
                                : "fa-solid fa-circle-left"
                            }`}
                          ></i>
                          <span className="ml-2">
                            {i.isOutbound ? "Outgoing " : "Incoming "}{" "}
                            {i.type === "Phone" ? "Call" : i.type}
                          </span>
                        </div>
                        <div>{i.createdBy && <div>By: {i.createdBy}</div>}</div>
                      </td>
                      <td>
                        {i.type === "Email" && (
                          <div>
                            <div
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "black",
                              }}
                            >
                              Subject: {i.emailDetails?.subject}
                            </div>
                            {/* {i.emailDetails?.content} */}
                            <div
                              style={{
                                fontSize: "13px",
                                background: "white",
                              }}
                            >
                              <div>
                                {i.content}
                                &nbsp;
                                <button
                                  className="btn-more"
                                  onClick={(event) =>
                                    showEmailContent(i, event)
                                  }
                                >
                                  ...more
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {i.type === "Text" && (
                          <React.Fragment>
                            {i.isOutbound && i.to && (
                              <div className="twilio-to">To: {i.to}</div>
                            )}
                            {!i.isOutbound && i.from && (
                              <div className="twilio-to">From: {i.from}</div>
                            )}

                            <div
                              dangerouslySetInnerHTML={{ __html: i.content }}
                            ></div>
                          </React.Fragment>
                        )}
                        {i.type === "Phone" && i.recordingId && (
                          <div>
                            {i.isOutbound && i.to && (
                              <div className="twilio-to">To: {i.to}</div>
                            )}
                            {!i.isOutbound && i.from && (
                              <div className="twilio-to">From: {i.from}</div>
                            )}

                            {getMinutesPassed(new Date(i.createdOn)) < 5 && (
                              <div>Recording is being processed.</div>
                            )}
                          </div>
                        )}
                        {i.type === "Phone" && !i.recordingId && (
                          <>
                            <div className="twilio-to">
                              {i.isOutbound && i.to && <div>To: {i.to}</div>}
                            </div>
                            <div className="twilio-to">
                              {!i.isOutbound && i.from && (
                                <div>From: {i.from}</div>
                              )}
                            </div>
                            <div>No recording available yet.</div>
                          </>
                        )}

                        <span>
                          {i.recordingId && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {getMinutesPassed(new Date(i.createdOn)) >= 5 && (
                                <div>
                                  <button
                                    style={{
                                      paddingBottom: "8px",
                                      paddingTop: "8px",
                                    }}
                                    onClick={(event) =>
                                      playRecording(event, i.recordingId)
                                    }
                                    className="btn btn-sm btn-success fa-solid fa-play"
                                  ></button>
                                </div>
                              )}

                              <div>
                                <button
                                  onClick={() => deleteRecording(cIndex, i.sid)}
                                  style={{
                                    paddingBottom: "8px",
                                    paddingTop: "8px",
                                  }}
                                  className="btn btn-sm btn-danger fa-solid fa-trash"
                                ></button>
                              </div>
                            </div>
                          )}

                          {i.type === "Email" &&
                            i.emailDetails &&
                            i.isOutbound === false && (
                              <div>
                                <button
                                  onClick={() => showSendMessage("Email", i)}
                                  className="btn btn-xs btn-outline-primary"
                                >
                                  Reply
                                </button>
                              </div>
                            )}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {showSendMessageModal && (
            <Modal
              title="Write message to tenant"
              onClose={setShowSendMessageModal}
            >
              <div>
                {messageMode === "Email" && (
                  <div className="mb-2">
                    <label>Subject:</label>
                    <input
                      ref={subjectContentRef}
                      className="form-control"
                      defaultValue={activeReply?.emailDetails?.subject}
                      disabled={!!activeReply}
                      type="text"
                      placeholder="Subject"
                    ></input>

                    <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                      <label>Attachments:</label>
                      <input type="file" multiple name="files" ref={emailRef} />
                    </div>
                  </div>
                )}
                <textarea
                  ref={messageContentRef}
                  rows={8}
                  className="form-control"
                ></textarea>
                <div className="text-right mt-2">
                  <button className="btn btn-success" onClick={doSendMessage}>
                    Send
                  </button>
                </div>
              </div>
            </Modal>
          )}

          {detailEmail && (
            <Modal
              title={detailEmail.emailDetails.subject}
              onClose={closeEmailDetailModal}
            >
              <div className="row">
                <div className="col-11">
                  <div>
                    <b>{detailEmail.isOutbound ? "Sent On" : "Received On"}:</b>{" "}
                    {localDate(detailEmail.createdOn)}
                  </div>
                  <div>
                    <b>From:</b> {detailEmail.emailDetails.fromName} {"<"}
                    {detailEmail.emailDetails.fromAddress}
                    {">"}
                  </div>

                  <div>
                    <b>To:</b>{" "}
                    {detailEmail.emailDetails.to?.map((t: any) => (
                      <span className="email-cc">
                        {t.name} ({t.email})
                      </span>
                    ))}
                  </div>

                  {detailEmail.emailDetails.cc?.length > 0 && (
                    <div>
                      <b>CC:</b>{" "}
                      {detailEmail.emailDetails.cc?.map((t: any) => (
                        <span className="email-cc">
                          {t.name} ({t.email})
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-1 text-right">
                  <button
                    onClick={() => showSendMessage("Email", detailEmail)}
                    className="btn btn-xs btn-outline-primary"
                  >
                    Reply
                  </button>
                </div>
              </div>

              <div
                className="mt-4"
                dangerouslySetInnerHTML={{
                  __html: detailEmail.emailDetails.content,
                }}
              ></div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );

  return render_data;
}

export default Lead;

export interface PhoneRecord {
  phone: string;
  name: string;
}

export function snackError(str: string) {
  let wnd = window as any;
  let durationInSeconds = 2;
  wnd["vt"].custom(
    str,
    {
      duration: durationInSeconds * 1000,
      fadeDuration: durationInSeconds * 1000,
      position: "top-right",
    },
    "error"
  );
}

export function snackSuccess(str: string) {
  let wnd = window as any;
  let durationInSeconds = 2;
  wnd["vt"].custom(
    str,
    {
      duration: durationInSeconds * 1000,
      fadeDuration: durationInSeconds * 1000,
      position: "top-right",
    },
    "success"
  );
}

export interface DataItem {
  Id: string;
  PropertyId: number;
  Name: string;
  FamilySize: number;
  IsCurrentResident: boolean;
  Type: string;
  Reason: string;
  Email: string;
  CellPhones: PhoneRecord[];
  Unit: string;
  LeaseBeginDate: string;
  ActualMoveOutDate: string;
  EmployeeName: string;
  MarketingChannel: string;
  Tags: string[];
  TermRent: number;
  LeaseTerm: number;
  Status:
    | "In Progress"
    | "Settlement"
    | "In Collections"
    | "Payment Pending"
    | "Correction Needed"
    | "Paid"
    | "Do Not Call"
    | "Broken PTP"
    | "Attorney"
    | "Promise to Pay";
  CreatedOn: string;
  PropertyName: string;
  LastContact: string | null;
  LastUpdated: string | null;
  Owner: string;
  Notes: Contact[];
  Communications: Contact[];
  IsEvictionProceedingsStarted: boolean;
  EndBalance: number;
  LeaseEndDate?: Date;
  NoticeToVacateDate?: Date;
  ExpectedMoveInDate?: Date;
}

export interface Contact {
  id: number | null;

  sid: string | null;
  content: string;
  createdOn: string;
  isOutbound: boolean;
  type: "Email" | "Phone" | "Text" | "Note";
  emailDetails: any;
  createdBy: string | null;

  //used for sms and calls
  to: string | null;

  recordingId: string | null;

  //used for sms and calls
  from: string | null;
  followUpDate: string | null;
  followUpDone: Date | null;
}

export interface Events {
  id: string | null;
  expiresOn: string | null;
  stripeStatus: string | null;
  stripePaymentStatus: string | null;
  createdOn: string;
  status: "Paid" | "Unpaid" | "Pending";
  amount: number | null;
  paymentUrl: string | null;
}

// export interface NoteRecord {
//   date: string;
//   person: string;
//   message: string;
//   followUpDate: string;
//   followUpDone: Date | null;
// }

export interface PhoneRecord {
  phone: string;
  name: string;
}
