import './LeadAppointments.css';

const Appointments = (props) => {
  return (
    <table className="table table-aligned-left table-striped table-v-middle">
      <thead>
        <tr>
          <th>Property</th>
          <th>Person</th>
          <th>Type</th>
          <th>Appointment Date</th>
          <th>Message</th>
          <th>Last Contacted</th>
          <th>Last Update</th>
        </tr>
      </thead>
      <tbody>
        {props.appointments.length === 0 && (
          <tr>
            <td colSpan="8" className="text-center">
              No appointments found
            </td>
          </tr>
        )}
        {props.appointments.map((a, ndx) => (
          <tr key={ndx}>
            <td>{a.PropertyName}</td>
            <td>
              <button
                className="btn btn-link btn-name"
                onClick={() => props.showUser(a.LeadId)}
              >
                {a.Name || "Unknown Person"}
              </button>
            </td>
            <td>{a.Type === "lead'" ? "Lead" : "Not Lead"}</td>
            <td>{a.AppointmentDate} {a.AppointmentTime}</td>
            <td>{a.Message}</td>
            <td>{a.LastContact}</td>
            <td>{a.LastUpdate}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default Appointments;
