import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "../Modal/Modal";
import LoadingContext from "../LoadingContext";
import moment from "moment";
import { DataItem, snackError } from "../Lead/Lead";
import config from "../config";
import "./Lister.css";
//let allData: DataItem[] = [];
let _notiInterval: any = null;

export default function Lister(props: any) {
  const showUser = props.showUser;

  const { setLoading } = useContext(LoadingContext)!;
  const [owners, setOwners] = useState([]);
  const searchRef = useRef<HTMLInputElement | null>(null);
  // const [ranges, setRanges] = React.useState([
  //   {
  //     min: null,
  //     max: null,
  //     name: "All",
  //     count: 0,
  //     active: true,
  //   },
  //   {
  //     min: 0,
  //     max: 1000,
  //     name: "0 - 1000",
  //     count: 0,
  //     active: false,
  //   },
  //   {
  //     min: 1001,
  //     max: 2000,
  //     name: "1000 - 2000",
  //     count: 0,
  //     active: false,
  //   },
  //   {
  //     min: 2001,
  //     max: null,
  //     name: "2001 - more",
  //     count: 0,
  //     active: false,
  //   },
  // ]);
  const [properties, setProperties] = React.useState([] as any[]);
  const [filteredData, setFilteredData] = React.useState([] as DataItem[]);
  const [pages, setPages] = React.useState([]);
  const [pageFilter, setPageFilter] = useState({
    page: 1,
    property: "All",
    status: "All",
    range: "All",
    search: null as string | null,
    owner: "All",
    residency: "All",
    evictions: "All",
    sort: null as any,
  });
  const [statuses, setStatuses] = React.useState([
    { name: "All", count: 0, amount: 0 },
    { name: "In Progress", count: 0, amount: 0 },
    { name: "Settlement", count: 0, amount: 0 },
    { name: "In Collections", count: 0, amount: 0 },
    { name: "Payment Pending", count: 0, amount: 0 },
    { name: "Correction Needed", count: 0, amount: 0 },
    { name: "Paid", count: 0, amount: 0 },
    { name: "Do Not Call", count: 0, amount: 0 },
    { name: "Broken PTP", count: 0, amount: 0 },
    { name: "Attorney", count: 0, amount: 0 },
    { name: "In Collections", count: 0, amount: 0 },
    { name: "Promise to Pay", count: 0, amount: 0 },
  ]);

  const getTimezones = () => {
    fetch(config.apiURL + "/timezones", {
      headers: {
        "content-type": "application/json",
        "x-karya-token": localStorage.getItem("token")!,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res.status;
        }
      })
      .then((res) => {
        localStorage.setItem("timezones", JSON.stringify(res));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    doFilter();
  }, [pageFilter]);

  const handleSortChange = (field: any) => {
    let direction = pageFilter.sort?.direction ?? "asc";
    if (direction === "asc") {
      direction = "desc";
    } else {
      direction = "asc";
    }
    setPageFilter((curr) => ({
      ...curr,
      page: 1,
      sort: {
        sortBy: field,
        direction: direction,
      },
    }));
  };

  const searchKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setPageFilter({
        page: 1,
        property: "All",
        status: "All",
        range: "All",
        search: searchRef.current!.value,
        owner: "All",
        residency: "All",
        evictions: "All",
        sort: null as any,
      });
    }
  };

  const handlePropertyChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPageFilter((curr) => ({
      ...curr,
      page: 1,
      property: event.target.value,
    }));
  };

  const pageChangeHandler = (event: any) => {
    setPageFilter((curr) => ({
      ...curr,
      page: +event.target.value,
    }));
  };

  const doFilter = () => {
    setLoading(true);
    fetch(config.apiURL + "/search", {
      headers: {
        "content-type": "application/json",
        "x-karya-token": localStorage.getItem("token")!,
      },
      method: "POST",
      body: JSON.stringify(pageFilter),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          if (res.status === 401) {
            window.location.href = "/external";
          }
          throw res.statusText;
        }
      })
      .then((res) => {
        // setBalance(res.overview["Total"]["Total"]);
        // setOverview(res.overview);
        setOwners(res.owners);
        setFilteredData(res.data);
        setPages(res.pages);
        setStatuses(res.statuses);
        // setRanges(res.ranges);
        setProperties(res.properties);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (_notiInterval) {
      clearInterval(_notiInterval);
    }
    setLoading(true);
    getTimezones();

    // if (window) {
    //   let wnd = window as any;
    //   wnd["InitTwilio"](config.apiURL + "/token");
    // }

    return () => {
      if (_notiInterval) {
        clearInterval(_notiInterval);
      }
    };
  }, []);

  const handleStatusChange = (name: string) => {
    setPageFilter((curr) => ({
      ...curr,
      page: 1,
      status: name,
    }));
  };

  const handleOwnerChange = (event: any) => {
    setPageFilter((curr) => ({
      ...curr,
      page: 1,
      owner: event.target.value,
    }));
  };

  const handleResidencyChange = (event: any) => {
    setPageFilter((curr) => ({
      ...curr,
      page: 1,
      residency: event.target.value,
    }));
  };

  const handleEvictionStatus = (event: any) => {
    setPageFilter((curr) => ({
      ...curr,
      page: 1,
      evictions: event.target.value,
    }));
  };

  const handleRangeChange = (name: string) => {
    setPageFilter((curr) => ({
      ...curr,
      page: 1,
      range: name,
    }));
  };

  const trimDate = (date: any) => {
    if (date) {
      return date.split(" ")[0];
    } else {
      return "-";
    }
  };

  const localDate = (date: any, includeHOUR: boolean = true) => {
    if (!date) {
      return <></>;
    } else {
      let dObject = date;
      // if (typeof date === "object") {
      //   dObject = date.toJSON();
      // }

      if (includeHOUR) {
        return (
          <span>
            {" "}
            {moment.utc(dObject).local().format("MM/DD/YYYY hh:mm A")}
          </span>
        );
      } else {
        return <span> {moment.utc(dObject).local().format("MM/DD/YYYY")}</span>;
      }
    }
  };

  const sortButton = (key: string, text: string) => {
    return (
      <React.Fragment>
        <button
          className="btn btn-xs btn-light mr-2"
          onClick={() => handleSortChange(key)}
        >
          {pageFilter.sort?.sortBy === key && (
            <>
              {pageFilter.sort?.direction === "asc" && (
                <i className="fa-solid fa-sort-up"></i>
              )}
              {pageFilter.sort?.direction === "desc" && (
                <i className="fa-solid fa-sort-down"></i>
              )}
            </>
          )}

          {pageFilter.sort?.sortBy !== key && (
            <i className="fa-solid fa-sort"></i>
          )}
        </button>
        {text}
      </React.Fragment>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "4px",
        }}
      >
        <div>
          <select
            value={pageFilter.property}
            className="form-control inline-block mb-2"
            id="property_drop_down"
            onChange={handlePropertyChange}
          >
            <option value={"All"}>All properties</option>
            {properties.map((opt, propertyIndex) => (
              <option key={propertyIndex} value={opt.id}>
                {opt.name}
              </option>
            ))}
          </select>
        </div>
        <div className="text-right">
          <div>
            <select
              style={{ minWidth: "unset" }}
              value={pageFilter.owner}
              className="form-control inline-block mr-2"
              onChange={handleOwnerChange}
            >
              <option key={0}>All Owners</option>
              {owners.map((o, ondx) => (
                <option key={ondx + 1} value={o}>
                  {o}
                </option>
              ))}
            </select>

            <select
              style={{ minWidth: "unset" }}
              value={pageFilter.residency}
              className="form-control inline-block mr-2"
              onChange={handleResidencyChange}
            >
              <option value={"All"}>All People</option>
              <option value={"Leads"}>Leads</option>
              <option value={"Current"}>Current Resident</option>
              <option value={"Moved Out"}>Moved Out</option>
              <option value={"Vendor"}>Vendor</option>
            </select>

            <select
              style={{ minWidth: "unset" }}
              className="form-control inline-block mr-2"
              onChange={handleEvictionStatus}
              value={pageFilter.evictions}
            >
              <option value={"All"}>All Eviction Status</option>
              <option value={"Not Started"}>Not Started</option>
              <option value={"Started"}>Started</option>
            </select>

            <input
              type="text"
              style={{ width: "300px" }}
              ref={searchRef}
              placeholder="Search"
              className="form-control inline-block"
              onKeyDown={searchKeyDown}
            ></input>
          </div>
        </div>
      </div>

      <div>
        {pages.length > 0 && (
          <div className="row">
            {statuses.map((i, ndx) => (
              <div
                key={ndx}
                className={
                  `col m-group mg` +
                  (ndx + 1) +
                  (pageFilter.status === i.name ? " active" : " ") +
                  (i.count === 0 ? " disabled" : "")
                }
                onClick={() => handleStatusChange(i.name)}
              >
                <label>{i.name}</label>
                <div>{i.count}</div>
              </div>
            ))}
          </div>
        )}

        {pages.length > 0 && (
          <div className="text-right mb-4 mt-2">
            Current Page:&nbsp;&nbsp;
            <select
              onChange={pageChangeHandler}
              value={pageFilter.page}
              className="form-control inline-block"
            >
              {pages.map((i, ondx) => (
                <option key={ondx} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
        )}

        <table className="table  table-v-middle mt-4">
          <thead>
            <tr className="my-table-dark">
              <th scope="col">{sortButton("Name", "Name")}</th>
              <th scope="col">Type & Reason</th>
              <th scope="col">{sortButton("Unit", "Unit")}</th>
              <th scope="col">Contact</th>
              <th scope="col">{sortButton("EndBalance", "Balance")}</th>
              {/* <th scope="col">{sortButton("TotalPaid", "Paid")}</th> */}
              <th scope="col">{sortButton("Owner", "Owner")}</th>
              <th scope="col">{sortButton("Status", "Status")}</th>
              <th scope="col">{sortButton("LastContact", "Last Contact")}</th>
            </tr>
          </thead>

          <tbody>
            {filteredData.map((data, dataIndex) => (
              <tr style={{ fontSize: "13px" }} key={dataIndex}>
                <td style={{ textAlign: "left" }}>
                  <button
                    className="btn btn-link btn-name"
                    onClick={() => showUser(data.Id)}
                  >
                    {data.Name}
                  </button>
                  <div>
                    {data.Type !== "lead" && (
                      <div
                        className={
                          data.IsCurrentResident ? "res-cur" : "res-mov"
                        }
                      >
                        {data.IsCurrentResident
                          ? "Current Resident"
                          : "Moved Out"}
                      </div>
                    )}
                    {data.Type === "lead" && (
                      <div className="res-lea">Lead</div>
                    )}
                  </div>
                </td>
                <td style={{textTransform:'capitalize', textAlign: 'left'}}>
                  {data.Type} {data.Reason && " - " + data.Reason}
                </td>
                <td style={{ textAlign: "left" }}>
                  {pageFilter.property === "All" ? data.PropertyName : null}
                  <div>{data.Unit}</div>
                </td>
                <td style={{ textAlign: "left" }}>
                  {data.CellPhones.map((i, ndx) => (
                    <div key={ndx} className="cell-phone">
                      {i.name}: {i.phone}
                    </div>
                  ))}
                  <div>Email: {data.Email}</div>
                </td>
                <td>
                  {data.EndBalance !== 0 && <span>${data.EndBalance}</span>}
                </td>
                {/* <td className="align-middle">${data.TotalPaid ?? 0}</td> */}
                <td>{data.Owner ?? "-"}</td>
                <td style={{ textAlign: "left" }}>
                  <div className="status-badge">{data.Status}</div>
                </td>
                <td>
                  <span>{localDate(data.LastContact)}</span>
                </td>
              </tr>
            ))}

            {filteredData.length === 0 && (
              <tr>
                <td colSpan={7} className="text-center">
                  No records have been found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {pages.length > 0 && (
          <div className="text-right mb-4 mt-2">
            Current Page:&nbsp;&nbsp;
            <select
              onChange={pageChangeHandler}
              value={pageFilter.page}
              className="form-control inline-block"
            >
              {pages.map((i, ondx) => (
                <option key={ondx} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </>
  );
}

export interface PropertyItem {
  name: string;
  code: number;
}

export interface FilterDataItem {
  property: PropertyItem;
  data: DataItem[];
}
