import React from "react";
import ReactDOM from "react-dom";

function ModalChild(props) {
  const handleOnClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  return (
    <div className={props.className ? props.className : "my-modal"}>
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button
                onClick={handleOnClose}
                type="button"
                className="btn btn-sm btn-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Modal(props) {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <ModalChild className={props.className} onClose={props.onClose} title={props.title}>
          {props.children}
        </ModalChild>,
        document.getElementById("modal-container")
      )}
    </React.Fragment>
  );
}
export default Modal;
