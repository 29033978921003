import "./App.css";
import { useEffect, useState } from "react";
import Chat from "../Chat/Chat";
import { stopRecording, initializeRecord } from "../recording";
import config from "../config";
import Lead, { snackError } from "../Lead/Lead";
import SidePhone from "../SidePhone/SidePhone";
import Lister from "../Lister/Lister";
import Modal from "../Modal/Modal";
import Rooms from "../Rooms/Rooms";
import RoomInfo from "../Rooms/RoomInfo";
import Notifications from "../Notifications/Notifications";
import Voicemails from "../Voicemails/Voicemails";
import Appointments from "../Appointments/Appointments";
import FollowUpLister from "../NotesAndFollowUps/FollowUpLister";


const MY_NAME = "Karya";

function Main() {
  const [JoinedRoom, setJoinedRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [voicemails, setVoicemails] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [followUps, setFollowUps] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [mode, setMode] = useState("phone");

  const disconnect = () => {
    window.connection.peerHasVideo = false;
    if (window.connection.ACTIVE_ROOM_TYPE === "VIDEO-CHAT") {
      try {
        stopRecording();
      } catch (e) {}
    }

    if (window.connection.peer) {
      window.connection.peer.disconnect();
      window.connection.peer.destroy();
    }
    if (window.connection.activeCall) {
      window.connection.activeCall.close();
    }
    if (window.connection.socket) window.connection.socket.disconnect();

    document.getElementById("video-grids").innerHTML = "";

    if (
      window.connection.ACTIVE_ROOM_TYPE === "VIDEO-CHAT" &&
      window.connection.myVideoStream
    )
      window.connection.myVideoStream
        .getTracks()
        .forEach((track) => track.stop());

    setJoinedRoom(null);

    document.getElementById("ddlStatus").value = "available";
    document.getElementById("ddlStatus").onchange();

    window.connection = {
      peer: null,
      socket: null,
      activeCall: null,
      peers: {},
      myVideoStream: null,
      isJoining: false,
      activeRoomId: null,
      peerHasVideo: false,
      ACTIVE_ROOM_TYPE: null,
      disconnectTimeout: null,
      property: null,
    };
  };

  const getMachineId = () => {
    let machineId = localStorage.getItem("MachineId");
    if (!machineId) {
      machineId = crypto.randomUUID().replace(/-/g, "");
      machineId = machineId.substring(0, 12);
      localStorage.setItem("MachineId", machineId);
    }
    return machineId;
  };

  const MEDIA_SETTINGS = {
    video: {
      facingMode: "user", // or 'environment' for the back camera on mobile
      width: { ideal: 640 }, // Ideal width
      height: { ideal: 480 }, // Ideal height
      frameRate: { ideal: 30, max: 60 },
    },
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: false,
      sampleRate: { ideal: 44100 },
    },
  };

  const init = (room) => {
    // if (!window.hostAddedStream) {
    //   window.hostAddedStream = [];
    // }

    console.log("asda");
    //let myVideo = document.getElementById("videoElement");
    let streamPromise =
      room.roomType === "VIDEO-CHAT"
        ? navigator.mediaDevices.getUserMedia(MEDIA_SETTINGS)
        : new Promise((succ) => succ(null));

    streamPromise.then((stream) => {
      console.log("incoming");
      // This function add the div which contains the video and the name. Basically it add our video to the screen

      if (stream != null) {
        window.connection.myVideoStream = stream;
        addVideoStream(stream, MY_NAME);
      }

      // eslint-disable-next-line no-undef
      window.connection.socket = io(config.SOCKET_ADDRESS, {
        auth: {
          token: localStorage.getItem("token"),
        },
        path: "/socket.io",
        // transports: ['polling'],
      });

      // eslint-disable-next-line no-undef
      window.connection.peer = new Peer(undefined, {
        host: config.PEER_HOST,
        port: config.PEER_PORT,
        path: "peerjs",
        // debug: "3",
        key: "add-new-id",
        config: {
          iceServers: [
            {
              urls: ["turn:stun.karyaapps.com:443?transport=tcp"],
              username: "karyameet",
              credential: "karya2024meet",
            },
          ],
        },
      });

      console.log("waiting socket");

      window.connection.socket.on("createMessage", (message) => {
        // if (isChatOpen() === false) {
        //   showChatBox();
        // }

        let isMe =
          message.sender.includes("Karya") || message.sender === MY_NAME;
        setMessages((current) => {
          return [
            ...current,
            {
              isMe: isMe,
              message: message.message,
            },
          ];
        });

        // let html = `
        // <div class="${isMe ? "my-message" : "received-message"}">
        //     <div>
        //         <p>${message.message}</p>
        //     </div>
        // </div>`;
        setTimeout(() => {
          const messagesContainer = document.getElementById("messages");
          messagesContainer.scrollTop = messages.scrollHeight;
        }, 300);
      });

      window.connection.socket.on("user-connected", (id, username) => {
        console.log("user connected");
        window.connection.peers[id] = window.connection.activeCall;
      });

      // window.connection.socket.on("user-connected", (id, username) => {
      //   console.log("userid:" + id);
      //   // if (room.roomType === "TEXT-CHAT") {
      //   //   showChatBox();
      //   // }
      //   connectToNewUser(id, stream, username); // We run this function and pass user's id, stream and user's name as arguments(Explnation At function)
      // });

      window.connection.socket.on("server-message", (message) => {
        if (message === "invalid-login-token") {
          alert("Redirecting you to login again.");
          window.location.href = "/";
        } else if (message === "device-needs-registration") {
          alert(message + ": " + getMachineId());
          window.connection.socket.disconnect();
          disconnect();
        } else if (message?.indexOf("property-id") > -1) {
          window.connection.property = message.split(":")[1];
          console.log("property is " + window.connection.property);
        }
      });

      window.connection.socket.on("user-disconnected", (id) => {
        if (window.connection.peers[id]) {
          window.connection.peers[id].close();
        }
        disconnect();
      });

      window.connection.disconnectTimeout = null;

      window.connection.peer.on("call", (call) => {
        // When We get a call

        console.log("accepting a call");
        if (window.connection.myVideoStream) {
          call.answer(window.connection.myVideoStream);
        } else {
          call.answer();
        }

        // if (room.roomType === "TEXT-CHAT") {
        //   showChatBox();
        // }

        // Answer the call with our stream
        call.on("stream", function (remoteStream) {
          if (!window.connection.peerHasVideo) {
            window.connection.peerHasVideo = true;
            window.connection.activeCall = call;
            // window.hostAddedStream.push(remoteStream.id);
            console.log("recieving the other stream");

            addVideoStream(remoteStream, ""); // And other user's stream to our window
          }
          // Get other user's stream
        });
      });

      window.connection.peer.on("open", (id) => {
        // When ever user joins every user is given a unique id and its very imposrtant to know their id when communicating
        console.log("opening");
        window.connection.socket.emit(
          "join-room",
          room.roomId,
          id,
          JSON.stringify({})
        );
        console.log("x1");

        // if (room.roomType === "TEXT-CHAT") {
        //   showChatBox();
        // }
      });
    });
  };

  const muteUnmute = () => {
    //find user's video element and mute so the other user can't hear the mic
    const tracks = window.connection.myVideoStream.getAudioTracks();
    if (tracks.length > 0) {
      const track = tracks[0];
      const mic = document.getElementById("mic");
      mic.classList.remove("btn-danger");
      mic.classList.remove("btn-info");
      if (track.enabled) {
        track.enabled = false;
        mic.classList.remove("btn-danger");
        mic.classList.add("btn-info");
        mic.innerHTML = "Unmute";
      } else {
        mic.classList.remove("btn-info");
        mic.classList.add("btn-danger");
        track.enabled = true;
        mic.innerHTML = "Mute";
      }
    }
  };

  const getFollowUps = () => {
    fetch(config.apiURL + "/follow-ups", {
      headers: {
        "x-karya-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setFollowUps(data);
      });
  };

  const addVideoStream = (stream, name) => {
    const videoEl = document.createElement("video"); // Create a video element
    if (name) {
      videoEl.id = "videoElement";
    } else {
      videoEl.id = "otherVideo";
    }
    videoEl.autoplay = true;
    console.log("AAAAA");
    console.log("name", name);
    let isMe = name === MY_NAME;
    videoEl.srcObject = stream; // Set the stream to the video element that we passed as arguments
    videoEl.muted = isMe;
    videoEl.addEventListener("loadedmetadata", async () => {
      console.log("metadata loaded");
      await videoEl.play();
    });
    const videoGrid = document.createElement("div"); // Create a div 'videoGrid' inside the "videoGridS" div
    videoGrid.classList.add("video-grid"); // add a class to videoGrid div
    if (isMe) {
      videoGrid.classList.add("my-video");
    }
    const videoGrids = document.getElementById("video-grids");
    videoGrids.appendChild(videoGrid); // append the name to the the div "videoGrid"
    videoGrid.append(videoEl); // append the video element to the the div "videoGrid"
    let totalUsers = document.getElementsByTagName("video").length; // Get all video elemets
    console.log("total-users", totalUsers);
    if (totalUsers > 1) {
      setTimeout(() => {
        console.log("initializing recording");
        initializeRecord(window.connection.activeRoomId);
      }, 1000);
    }
  };

  const joinRoom = (room) => {
    window.connection.ACTIVE_ROOM_TYPE = room.roomType;
    setJoinedRoom(room);
    setTimeout(() => {
      let targetRoomId = room.roomId;
      window.connection.activeRoomId = targetRoomId;
      if (window.connection.isJoining === true) {
        return;
      }
      window.connection.isJoining = true;
      fetch(config.apiURL + "/is-room-available?roomId=" + targetRoomId, {
        headers: {
          "content-type": "application/json",
          "x-karya-token": localStorage.getItem("token"),
        },
      })
        .then(async (res) => {
          if (res.ok) {
            document.getElementById("ddlStatus").value = "not-available";
            document.getElementById("ddlStatus").onchange();
            // let activeAvailableRoom = availableRooms.find(
            //   (i) => i.roomId === targetRoomId
            // );
            // const room_html = getRoomHTML(activeAvailableRoom, false);
            // document.getElementById("pnlRoomDetails").innerHTML = room_html;
            // document.getElementById("pnlRoomDetails").classList.remove("hidden");

            init(room);

            // document.getElementById("rooms").classList.add("hidden");
            // document.getElementById("pnlMeeting").classList.add("hidden");
            // document.getElementById("pnlJoined").classList.remove("hidden");
          }
          window.connection.isJoining = false;
        })
        .finally(() => {
          window.connection.isJoining = false;
        });
    }, 500);
  };

  const sendMessage = (text) => {
    if (text) {
      window.connection.socket.emit("messagesend", {
        message: text,
        sender: "Karya",
      });
    }
  };

  const showUser = (id) => {
    if (!id) {
      document.body.style.overflow = "unset";
      setActiveUser(null);
      return;
    } else {
    }

    fetch(config.apiURL + "/item/" + id, {
      headers: {
        "x-karya-token": window.localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        document.body.style.overflow = "hidden";
        setActiveUser(data);
      })
      .catch((err) => {
        window["vt"].custom(
          "An error occured while fetching candidate data",
          {
            duration: 1000,
            fadeDuration: 1000,
            position: "top-right",
          },
          "error"
        );
      });
  };

  const unreadNotificationCount = () => {
    return notifications.filter((i) => i.IsRead === false).length;
  };

  const fetchVoiceAndNotifications = () => {
    fetch(config.apiURL + "/notifications", {
      headers: {
        "content-type": "application/json",
        "x-karya-token": localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          snackError("An error occured getting the voicemail");
        }
      })
      .then((res) => {
        if (JSON.stringify(res) !== JSON.stringify(notifications))
          setNotifications(res);
      });

    fetch(config.apiURL + "/voicemail", {
      headers: {
        "content-type": "application/json",
        "x-karya-token": localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          snackError("An error occured getting the voicemail");
        }
      })
      .then((res) => {
        if (JSON.stringify(voicemails) !== JSON.stringify(res))
          setVoicemails(res);
      });
  };

  const unreadVoicemailCount = () => {
    return voicemails.filter((x) => x.Listened === false).length;
  };

  const getAppointments = () => {
    fetch(config.apiURL + "/appointments", {
      headers: {
        "x-karya-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAppointments(data);
      });
  };

  useEffect(() => {
    window.connection = {
      peer: null,
      socket: null,
      activeCall: null,
      peers: {},
      myVideoStream: null,
      isJoining: false,
      activeRoomId: null,
      peerHasVideo: false,
      ACTIVE_ROOM_TYPE: null,
      disconnectTimeout: null,
      property: null,
    };

    window.showCallingUser = (req) => {
      // const sid = req.sid;
      // const number = req.number.replace("+1", "").replaceAll("-", "");
      fetch(
        config.apiURL + "/find-number?number=" + encodeURIComponent(req.number),
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "x-karya-token": localStorage.getItem("token"),
          },
          body: JSON.stringify(req),
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            console.log("someone is calling but we dont know the number");
          }
        })
        .then((res) => {
          setActiveUser(() => {
            document.body.classList.add("hide-scroll");
            return res;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (window.location.href.indexOf("token") > -1) {
      let token = window.location.href.split("token=")[1];
      window.localStorage.setItem("token", token);
      window.location.href = "/";
      // setIsLoggedIn(true);
      // window.history.pushState({}, document.title, "/");
    } else if (window.localStorage.getItem("token") == null) {
      fetch(config.apiURL + "/get-login-url", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          response.json().then((data) => {
            window.location.href = data.url;
          });
        })
        .catch(() => {});
    } else {
      //user is logged in
      //remove query from url
      fetch(config.apiURL + "/check", {
        headers: {
          "x-karya-token": window.localStorage.getItem("token"),
        },
      })
        .then(async (response) => {
          if (response.ok) {
            setIsLoggedIn(true);
            if (!localStorage.getItem("name")) {
              const data = await response.json();
              localStorage.setItem("name", data.name);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      getAppointments();
      getFollowUps();
      fetchVoiceAndNotifications();
    }

    (async () => {
      try {
        await navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then((stream) => {
            stream.getTracks().forEach((track) => track.stop());
          });
      } catch (e) {
        alert(
          "You need to allow camera & microphone to use video call. Please allow if you want to video chat with our staff."
        );
      }
    })();
  }, []);

  const logOut = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <>
      {localStorage.getItem("name") && isLoggedIn && (
        <>
          <div className="site-header">
            <div className="brand-name">
              karyalead
              <i
                style={{ fontSize: "13px", marginLeft: "10px" }}
                className="fa fa-phone"
              ></i>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "10px" }}>
                Hello {localStorage.getItem("name")}
              </span>
              <button onClick={logOut} className="btn btn-xs btn-danger">
                <i className="fa-solid fa-sign-out"></i>&nbsp;&nbsp;Log Out
              </button>
            </div>
          </div>

          <div style={{ display: "flex" }} className="App">
            <SidePhone />

            <div
              style={{
                flex: "auto",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <ul className="nav nav-tabs mt-4" style={{ width: "100%" }}>
                <li className="nav-item">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMode("phone");
                    }}
                    class={mode === "phone" ? "nav-link active" : "nav-link"}
                    aria-current="page"
                  >
                    People
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMode("follow-up");
                    }}
                    class={
                      mode === "follow-up" ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                  >
                    Follow Ups
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMode("rooms");
                    }}
                    class={mode === "rooms" ? "nav-link active" : "nav-link"}
                    aria-current="page"
                  >
                    Rooms
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMode("voicemails");
                    }}
                    class={
                      mode === "voicemails" ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                  >
                    Voicemails ({unreadVoicemailCount()})
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMode("notifications");
                    }}
                    class={
                      mode === "notifications" ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                  >
                    Notifications ({unreadNotificationCount()})
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setMode("appointments");
                    }}
                    class={
                      mode === "appointments" ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                  >
                    Appointments ({appointments.length})
                  </button>
                </li>
              </ul>

              <div
                className="tab-borders"
                style={{
                  padding: "10px",
                  display: mode === "phone" ? "block" : "none",
                }}
              >
                {activeUser && (
                  <div>
                    <Modal
                      className={"no-padding"}
                      title={activeUser.PropertyName + " - " + activeUser.Name}
                      onClose={() => showUser(null)}
                    >
                      <Lead user={activeUser} />
                    </Modal>
                  </div>
                )}
                <Lister showUser={showUser} />
              </div>

              <div
                className="tab-borders"
                style={{ display: mode === "rooms" ? "block" : "none" }}
              >
                <div>
                  {JoinedRoom && (
                    <div id="pnlJoined">
                      <div className="d-flex justify-content-between mb-3">
                        {JoinedRoom.roomType === "VIDEO-CHAT" && (
                          <button
                            className="btn btn-lg btn-danger"
                            id="mic"
                            onClick={muteUnmute}
                          >
                            Mute
                          </button>
                        )}
                        <button
                          className="btn btn-lg btn-warning"
                          onClick={disconnect}
                        >
                          Leave
                        </button>
                      </div>
                      <div
                        id="video-grids"
                        style={{
                          display:
                            JoinedRoom.roomType === "VIDEO-CHAT"
                              ? "block"
                              : "none",
                        }}
                      >
                        {/* <video id="videoElement" autoPlay={true}></video> */}
                      </div>
                      <div className="row">
                        <div
                          style={{
                            display:
                              JoinedRoom.roomType === "VIDEO-CHAT"
                                ? "block"
                                : "none",
                          }}
                          className="col-md-5"
                        >
                          <div id="canvasArea"></div>
                        </div>
                        <div
                          className={
                            JoinedRoom.roomType === "VIDEO-CHAT"
                              ? "col-md-7"
                              : "col-md-12"
                          }
                        >
                          <RoomInfo room={JoinedRoom} />
                          <Chat send={sendMessage} messages={messages} />
                        </div>
                      </div>
                    </div>
                  )}
                  {!JoinedRoom && (
                    <Rooms showUser={showUser} joinRoom={joinRoom} />
                  )}
                </div>
              </div>

              {mode === "appointments" && (
                <div className="tab-borders">
                  <Appointments
                    showUser={showUser}
                    appointments={appointments}
                  />
                </div>
              )}

              {mode === "follow-up" && (
                <div className="tab-borders">
                  <FollowUpLister followUps={followUps} showUser={showUser} />
                </div>
              )}

              {mode === "notifications" && (
                <div className="tab-borders">
                  <Notifications
                    showUser={showUser}
                    notifications={notifications}
                    setNotifications={setNotifications}
                  />
                </div>
              )}

              {mode === "voicemails" && (
                <div className="tab-borders">
                  <Voicemails
                    showUser={showUser}
                    voicemails={voicemails}
                    setVoicemails={setVoicemails}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div></div>
    </>
  );
}

export default Main;
