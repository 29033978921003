import Modal from "../Modal/Modal";
import Moment from "react-moment";
import "./Rooms.css";
import config from "../config";

const { useState, useEffect } = require("react");
const ringingSound = new Audio("/ringing.mp3");

function Rooms(props) {
  let [rooms, setRooms] = useState([]);
  let [roomToWatch, setRoomToWatch] = useState(null);
  let [isRinging, setIsRinging] = useState(false);
  let [loadingRoomDetails, setLoadingRoomDetails] = useState(false);

  const FETCH_ROOMS_INTERVAL = 10000;

  let showRoomDetails = (room) => {
    setLoadingRoomDetails(true);
    fetch(config.apiURL + "/get-room-details?roomId=" + room.roomId, {
      headers: {
        "Content-Type": "application/json",
        "x-karya-token": window.localStorage.getItem("token"),
      },
    })
      .then((response) => {
        response.json().then((data) => {
          setRoomToWatch(data);
        });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingRoomDetails(false);
      });
  };

  const showPersonColumn = props.hidePersonColumn === true ? false : true;

  const joinRoom = (room) => {
    props.joinRoom(room);
    ringingSound.currentTime = 0;
    ringingSound.pause();
    setIsRinging(false);
    addNotRingFor(room.roomId);
    if (!props.leadId) {
      if (window.fetchRoomInterval) clearInterval(window.fetchRoomInterval);
      window.fetchRoomInterval = setInterval(fetchRooms, FETCH_ROOMS_INTERVAL);
    }
  };

  const getNotRingFor = () => {
    if (!window.doNotRingFor) {
      window.doNotRingFor = [];
    }
    return window.doNotRingFor;
  };

  const addNotRingFor = (roomId) => {
    getNotRingFor().push(roomId);
  };

  const fetchRooms = () => {
    const query = props.leadId ? "?leadId=" + props.leadId : "";
    fetch(config.apiURL + "/rooms" + query, {
      headers: {
        "Content-Type": "application/json",
        "x-karya-token": window.localStorage.getItem("token"),
      },
    })
      .then((response) => {
        ringingSound.currentTime = 0;
        ringingSound.pause();
        setIsRinging(false);


        response.json().then((data) => {
          const availableRooms = data.filter(
            (room) => room.users.length === 1 && room.endedOn === null
          );
          const notAvailableRooms = data.filter(
            (room) => !(room.users.length === 1 && room.endedOn === null)
          );
          setRooms([...availableRooms, ...notAvailableRooms]);
          const canRing =
            availableRooms.length > 0 &&
            availableRooms.filter(
              (room) => !getNotRingFor().includes(room.roomId)
            ).length > 0;
          if (canRing) {
            if (isRinging === false) {
              if (window.interacted) {
                if (
                  document.getElementById("ddlStatus").value === "available"
                ) {
                  setIsRinging(true);
                  ringingSound.currentTime = 0;
                  ringingSound.play();
                  console.log("Ringing");
                }
              }
            }
          } else if (isRinging === true) {
            ringingSound.currentTime = 0;
            ringingSound.pause();
            setIsRinging(false);
          }
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchRooms();
    if (!props.leadId) {
      if (window.fetchRoomInterval) clearInterval(window.fetchRoomInterval);
      window.fetchRoomInterval = setInterval(fetchRooms, FETCH_ROOMS_INTERVAL);
    }

    setInterval(() => {
      const isAvailable = document.getElementById("ddlStatus").value === "available";
      if (isAvailable === false && isRinging === true) {
        setIsRinging(false);
      }
    }, 1000);
  }, []);

  return (
    <div id="rooms">
      <div>
        <table className="table table-striped recordings-table">
          <thead>
            <tr>
              {showPersonColumn && <th>Property</th>}
              {showPersonColumn && <th>Person</th>}
              <th>Started</th>
              <th>Room #</th>
              <th>Duration</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rooms.map((room) => (
              <tr
                key={room.roomId}
                className={
                  room.users.length === 1 && room.endedOn === null
                    ? "active-call"
                    : ""
                }
              >
                {showPersonColumn && <td>{room.propertyName}</td>}

                {showPersonColumn && (
                  <td>
                    <button
                      onClick={() => props.showUser(room.leadId)}
                      className="btn btn-link"
                    >
                      {room.creator?.name || "Person Details"}
                    </button>
                  </td>
                )}

                <td style={{ fontSize: "12px", width: "200px" }}>
                  {new Date(room.createdOn).toLocaleString()}
                </td>
                <td style={{ fontSize: "12px" }}>{room.roomId}</td>
                <td>
                  {room.endedOn === null ? (
                    "Recording"
                  ) : (
                    <Moment date={room.endedOn} duration={room.createdOn} />
                  )}
                </td>

                <td className="text-right">
                  {room.users.length === 1 && room.endedOn === null && (
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={() => {
                        joinRoom(room);
                      }}
                    >
                      Join Room
                    </button>
                  )}

                  {room.endedOn && (
                    <button
                      className="btn btn-sm btn-outline-primary"
                      disabled={loadingRoomDetails}
                      onClick={() => {
                        showRoomDetails(room);
                      }}
                    >
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  )}
                </td>
              </tr>
            ))}

            {rooms.length === 0 && (
              <tr>
                <td colSpan={showPersonColumn ? 6 : 4}>No rooms found</td>
              </tr>
            )}
          </tbody>
        </table>

        {roomToWatch && (
          <Modal
            title={"Room: " + roomToWatch?.roomId}
            onClose={() => setRoomToWatch(null)}
          >
            {roomToWatch?.videoStreamURL && (
              <>
                <video
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "10px",
                  }}
                  src={roomToWatch?.videoStreamURL}
                  autoPlay
                />
              </>
            )}

            {roomToWatch?.creator?.roomType === "VIDEO-CHAT" &&
              !roomToWatch?.videoStreamURL && (
                <div>Video is still processing.</div>
              )}

            {roomToWatch?.messages && roomToWatch?.messages.length > 0 && (
              <>
                <h4>Messages</h4>
                <div className="chatContainer">
                  {roomToWatch?.messages.map((message) => (
                    <div
                      className={
                        message.from?.includes("Karya")
                          ? "my-message"
                          : "received-message"
                      }
                    >
                      <div>
                        {message.from}: {message.message}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Modal>
        )}
      </div>
    </div>
  );
}

export default Rooms;
