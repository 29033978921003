import React from "react";

export class LoadingContextType {
    public setLoading(val: boolean) {
        document.body.classList.remove("loading");
        if (val === true)
            document.body.classList.add("loading");

    }
}

const LoadingContext = React.createContext<LoadingContextType | null>(null);
export default LoadingContext;